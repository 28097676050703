import { captureMessageForSentry } from './sentry-helper';
import type { WebLocale } from '@/enums/web-locale';

const USER_GEO_LOCATION_LOCALSTORAGE_KEY = 'geo_location';
const USER_GEO_SUBDIVION_LOCALSTORAGE_KEY = 'subdivision';
const SUBDIVISION_ARRAY_KEY = 2;

export type CountryCode = Uppercase<WebLocale>;

/**
 * Get the user's geolocation from the new API endpoint.
 *
 * We are getting the Country and the Subdivision code by using the ISO 3166-2
 * Codes for the representation of names of countries and their subdivisions
 * https://www.wikidata.org/wiki/Property:P300
 *
 * The code uses the structure:
 * The first part is the ISO 3166-1 alpha-2 code of the country
 * The second part is a string of up to three alphanumeric characters
 *
 * Examples:
 *
 * Code   Country         Region                    API Endpoint Payload
 * USCA   United States   California                US,,USCA
 * USTX   United States   Texas                     US,,USTX
 * GBENG  United Kingdom  England                   GB,,GBENG
 * IT62   Italy           Naples                    IT,Naples,IT72
 * ESMD   Mexico          Barcelona                 ES,Barcelona,ESCT
 * DK84   Denmark         Copenhagen                DK,Copenhagen,DK84
 * AUNSW  Australia       New South Wales (NSW)     AU,Sydney,AUNSW
 * NZAUK  New Zeland      Auckland                  NZ,Auckland,NZAUK
 * VNSG   Vietnam         Ho Chi Minh City          VN,Ho Chi Minh City,VNSG
 */
export const fetchUserGeoLocation = async (): Promise<string | null> => {
	return await fetch(JW_CONFIG.GEOLOCATION_URL, {
		cache: 'no-store',
	})
		.then(response => {
			if (response.ok) {
				return response.headers.get('X-Client-Geo-Location');
			}

			if (response.status === 404) throw new Error('404, Not found');
			if (response.status === 500) throw new Error('500, internal server error');

			// For any other server error
			throw new Error(`Response endpoint status: ${response.status}`);
		})
		.catch((error: any) => {
			captureMessageForSentry(
				"[Fetch user's geolocation from API]:",
				{
					error,
					endpoint: JW_CONFIG.GEOLOCATION_URL,
					where: '[geo-locaiton-helper.ts] fetchUserGeoLocation()',
				},
				'error'
			);

			return null;
		});
};

/**
 * Call the Geo-Location of the user, and save the return string to local storage.
 * We will split into an array and save it to localStorage. This can be used in the
 * application to determine the country, reigion and subdivision that the user
 * is visiting from
 *
 * Structure: "countryCode,region,subdivision"
 *
 * - USER_GEO_LOCATION_LOCALSTORAGE_KEY: will save the full geolocaiton structure
 * - USER_GEO_SUBDIVION_LOCALSTORAGE_KEY: will save the subdivision,
 *   which can be used to check which user consent they must follow
 */
export const storeUserGeoLocation = async () => {
	try {
		const geoLocation = await fetchUserGeoLocation();

		// split location into an array with the [countryCode, region, subdivision]
		const location = geoLocation?.split(',') || [];

		localStorage.setItem(USER_GEO_LOCATION_LOCALSTORAGE_KEY, JSON.stringify(location));
		localStorage.setItem(USER_GEO_SUBDIVION_LOCALSTORAGE_KEY, location[SUBDIVISION_ARRAY_KEY]);
	} catch (error: any) {
		captureMessageForSentry(
			"[Save user's geolocation from localStorage]:",
			{ error, where: '[geo-locaiton-helper.ts] storeUserGeoLocation()' },
			'error'
		);
	}
};

/**
 * Get the array of GeoLocation, which is generated by the Geo Location API
 * Structure: [CountryCode, Region, Subdivision]
 *
 * @returns {string[]}
 */
export const getUserGeoLocation = (): string[] => {
	try {
		return JSON.parse(localStorage.getItem(USER_GEO_LOCATION_LOCALSTORAGE_KEY) || '[]');
	} catch (error: any) {
		captureMessageForSentry(
			'[get users geoLocation array from localStorage]:',
			{ error, where: '[geo-locaiton-helper.ts] getUserGeoLocation()' },
			'error'
		);

		return [];
	}
};

/**
 * Gets the 2 digit country Code from the the first array item
 * located in the local storage's geo_location
 *
 * @returns {CountryCode | null}
 */
export const getUserLocationCountryCode = (): CountryCode | null => {
	try {
		const [countryCode] = getUserGeoLocation();
		return countryCode as CountryCode;
	} catch (error: any) {
		captureMessageForSentry(
			"[get users CountryCode from geolocation's localStorage]:",
			{ error, where: '[geo-locaiton-helper.ts] getUserLocationCountryCode()' },
			'error'
		);

		return null;
	}
};

/**
 * Get the subdivision code from localStorage
 *
 * @returns {string | null}
 */
export const getUserLocationSubdivision = (): string | null => {
	try {
		return localStorage.getItem(USER_GEO_SUBDIVION_LOCALSTORAGE_KEY);
	} catch (error: any) {
		captureMessageForSentry(
			"[get users Subdivision from geolocation's localStorage]:",
			{ error, where: '[geo-locaiton-helper.ts] getUserLocationSubdivision()' },
			'error'
		);

		return null;
	}
};
