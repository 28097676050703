
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Content } from '@/interfaces/components/content';

@Component({
	name: 'ContentSSR',
})
export default class ContentSSR extends Vue implements Content {
	async scrollToTop(duration = 0, behavior: 'smooth' | 'auto' = 'smooth'): Promise<void> {
		if (globalThis.window != null) {
			const contentHeader = document.querySelector('#content-header');
			const top = contentHeader?.getBoundingClientRect().height ?? 0;

			if (window.scrollY > top) {
				setTimeout(() => {
					window.scroll({ top, behavior });
				}, duration);

				return new Promise((resolve, reject) => {
					const failed = setTimeout(() => {
						reject();
					}, 3000);

					const scrollHandler = () => {
						if (window.pageYOffset === top) {
							window.removeEventListener('scroll', scrollHandler);
							clearTimeout(failed);
							resolve();
						}
					};

					if (window.pageYOffset === top) {
						clearTimeout(failed);
						resolve();
					} else {
						window.addEventListener('scroll', scrollHandler);
					}
				});
			}
		}
	}
}
