import { getVm } from '@/helpers/vm-helper';
import TitleReorderNewBuyBox, {
	TitleReorderNewBuyBoxName,
	TitleReorderNewBuyBoxVariants,
} from '@/components/experiments/title-reorder-new-buybox/index';
import { ExperimentTrackEventOptions } from '@/components/experiments/Core/types';

export const trackTitleDetailRedesignNewBuyBoxExp = (
	TrackingOptions: ExperimentTrackEventOptions<TitleReorderNewBuyBoxVariants>,
	lastRoute = false
) => {
	// const vm = getVm();
	// const activeVariant =
	// 	vm?.$store.getters['experiment/activeVariantsWithControlGroup'][TitleReorderNewBuyBoxName] ?? null;
	// const isUserLoggedIN = vm?.$store.getters['user/isLoggedIn']() ?? false;
	// const lastRouteName = vm?.$store.state.routing.lastRoute?.name;
	// const isShowPage = (lastRoute ? lastRouteName : vm?.$route.name) === 'app.detail.show';
	// if (activeVariant && !isUserLoggedIN && isShowPage) {
	// 	TitleReorderNewBuyBox.trackEvent({ property: 'show_season', ...TrackingOptions });
	// }
};

export const loadMicrosoftClarity = () => {
	if (document.getElementById('clarity-script')) {
		return;
	}
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.defer = true;
	script.async = true;
	script.id = 'clarity-script';
	script.innerHTML = `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "ffbppisl6e");`;

	document.head.appendChild(script);
};
