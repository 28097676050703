import { btoa } from '@/helpers/base64-helper';

/** Generate a random string with the specified length. */
export function generateRandomString(length = 22, radix?: number): string {
	const BASE64URICHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_'.split('');
	radix = radix || BASE64URICHARS.length;
	const newId = [];
	for (let i = 0; i < length; i++) {
		newId[i] = BASE64URICHARS[0 | (Math.random() * radix)];
	}
	return newId.join('');
}

/**
 * Generates a valid 22 character JustWatch Id
 * @param tag tag on ID to help filter by platform
 */
export function generateJwId(tag = 'C') {
	// `crypto.randomUUID` is still very new and might not be in all browsers
	const uuid = (crypto.randomUUID?.() ?? generateRandomString(36)).replace(/-/g, '');
	const byteArray = new Uint8Array(uuid.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
	const jwId = btoa(String.fromCharCode(...byteArray))
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.slice(0, 22);

	return jwId.substring(0, 16) + tag + jwId.substring(17);
}
