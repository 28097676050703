import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';

import type { Store } from 'vuex';

export type ServerHeaders = { [key: string]: string };

export interface ApolloClientConfig {
	store: Store<any>;
	headers?: ServerHeaders;
	ssr?: boolean;
}

export function getAppVersion() {
	return `${process.env.VERSION}-web-${process.server ? 'ssr' : 'web'}`;
}

type CustomHeaders = {
	'App-Version'?: string;
	'DEVICE-ID'?: string;
	'Experiments-Variants'?: string;
};

const withAccessToken = (store: any) =>
	new ApolloLink((operation, forward) => {
		const { accessToken } = store.state.user;
		if (accessToken) {
			operation.setContext(({ headers }: { headers: any }) => ({
				headers: {
					...headers,
					Authorization: `Bearer ${accessToken}`,
				},
			}));
		}

		return forward(operation);
	});

const withAuthMiddleware = (store: any) =>
	new ApolloLink((operation, forward) => {
		/*
		This function will be called each time we make a GraphQL query or mutation.
		New users don't have an access token until they sign up.
		Since we cannot reinitialize ApolloClient once we get the access token and save it as a header,
		this code will be checking for it on each query or mutation.
	*/
		const { jwId } = store.state.user;
		const customHeaders: CustomHeaders = {
			'App-Version': getAppVersion(),
		};

		if (jwId) {
			customHeaders['DEVICE-ID'] = jwId;
		}

		operation.setContext(({ headers }: { headers: any }) => ({
			headers: {
				...headers,
				...customHeaders,
			},
		}));

		return forward(operation);
	});

const withHeaders = (customHeaders?: ServerHeaders) =>
	new ApolloLink((operation, forward) => {
		operation.setContext(({ headers = {} }) => ({
			http: {
				includeExtensions: false,
				includeQuery: true,
			},
			headers: {
				...headers,
				...customHeaders,
			},
		}));

		return forward(operation);
	});

// HTTP connection to the API
const withHttpLink = (): ApolloLink =>
	createHttpLink({
		uri: process.server ? JW_CONFIG.GRAPHQL_INTERNAL_URL : JW_CONFIG.GRAPHQL_URL,
		fetch,
	});

const withRecommanderHeader = (store: Store<any>) =>
	new ApolloLink((operation, forward) => {
		/** Recommander EXPERIMENT */
		const variant = store.getters['experiment/getMLExperimentsVariants']('titleDetail').join(';');
		const customHeaders: CustomHeaders = {};
		if (variant) {
			customHeaders['Experiments-Variants'] = variant;
		}
		/** Recommander EXPERIMENT */
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				...customHeaders,
			},
		}));

		return forward(operation);
	});

const withRetry = (): RetryLink =>
	new RetryLink({
		delay: {
			initial: 300, // ms
			max: 5000, // ms
			jitter: true, // randomize between initial and max
		},
		attempts: {
			max: 2,
			retryIf: (error, _operation) => {
				const { name, statusCode } = error;
				console.error('[RetryLink]', name, statusCode);
				return !!error;
			},
		},
	});

const withResponseLogger = (headers?: ServerHeaders): ApolloLink => {
	console.debug(`[DEBUG_APOLLO] Apollo Request/Response Logger Enabled`);
	try {
		console.debug(`[DEBUG_APOLLO] Apollo Headers`, JSON.stringify(headers));
	} catch {}

	return new ApolloLink((operation, forward) => {
		try {
			console.debug(
				'[DEBUG_APOLLO] Request',
				JSON.stringify({ isServer: process.server, context: operation.getContext() })
			);
			console.debug('\n');
		} catch {}

		return forward(operation).map(result => {
			try {
				console.debug(
					'[DEBUG_APOLLO] Response',
					JSON.stringify({ isServer: process.server, context: operation.getContext() })
				);
				console.debug('\n');
			} catch {}

			return result;
		});
	});
};

const withJwSession = (store: Store<any>) =>
	setContext(async (_operation, { headers = {} }) => {
		const now = new Date().getTime();
		const { jwSessionExpires } = store.state.app;
		if ((jwSessionExpires || 0) < now) {
			await store.dispatch('app/fetchJwSession');
		}
		const jwSession = await store.state.app.jwSession;
		return {
			headers: {
				...headers,
				'Jw-Session': jwSession,
			},
		};
	});

export {
	withAccessToken,
	withAuthMiddleware,
	withHeaders,
	withHttpLink,
	withRecommanderHeader,
	withResponseLogger,
	withRetry,
	withJwSession,
};
