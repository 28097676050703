import { getAxios } from '@/helpers/axios-helper';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export const APP_PLATFORM = 'webapp';
export const APP_VERSION = process.env.VERSION;
export const APP_COMMIT_HASH = BUILD_CONFIG.commitShortHash;

let loading = false;
let resolve: (val: string) => void;
let reject: (reason: string) => void;

// STATE
const state = () => ({
	seoUser: true,
	jwSession: null as Promise<string> | null, // used for graphql title detail queries to circumvent 'poisoning'
	jwSessionExpires: null as number | null,
});

export const JW_SESSION_DURATION = 60 * 60 * 6 * 1000; // 6 hours

export type AppState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<AppState, any> = {
	isSeoUser(state): boolean {
		return state.seoUser;
	},
};

// ACTIONS
const actions: ActionTree<AppState, any> = {
	setSeoUser({ commit }, value: boolean) {
		commit('SET_SEO_USER', value);
	},
	async fetchJwSession({ commit }) {
		// check if axios is already fetching
		if (loading) {
			return;
		}
		loading = true;
		commit('RESET_JW_SESSION');
		try {
			// part of our scraping protection on the client
			const { origin } = location;
			const res = await getAxios().head(origin);
			const { headers } = res;
			commit('RESOLVE_JW_SESSION', headers['jw-session']);
		} catch (err) {
			console.error("couldn't get new JW session token", err);
			const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
			captureMessageForSentry('[app.store.ts]', { err, where: 'fetchJwSession' }, 'error');
		} finally {
			loading = false;
		}
	},
};

// MUTATIONS
const mutations: MutationTree<AppState> = {
	SET_SEO_USER(state, value: boolean) {
		state.seoUser = value;
	},
	RESET_JW_SESSION(state) {
		if (resolve) {
			// make sure to resolve the promise to not create any stalled scenarios.
			// calling `resolve` more than once isn't hurtful.
			resolve('');
		}
		const promise = new Promise(res => {
			resolve = res;
		});
		Vue.set(state, 'jwSession', promise);
	},
	RESOLVE_JW_SESSION(state, value: string) {
		resolve(value);
		const now = new Date().getTime();
		state.jwSessionExpires = now + JW_SESSION_DURATION;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<AppState, any>;
