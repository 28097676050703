import { UpcomingReleaseLabel } from '@/@types/graphql-types';
import { getVm } from '@/helpers/vm-helper';

/**
 * shift a given date by a number of days forwards or backwards and make sure the given date is of a certain timezone.
 * @param timeZone name of the timezone, e.g. 'Europe/Berlin', usually store.getters['language/localeObject].timezone is taken
 * @param options
 * @param shiftDays positive or negative number of days to shift
 * @param targetDate
 * @returns
 */
export const shiftDateWithTimezone = (timeZone: string, shiftDays = 0, targetDate = new Date()) => {
	// we can hard code en-US because in the end we're only interested in the Date object
	// if time shall not be reset, then use targetDate.toLocaleString.
	const localisedDateString = targetDate.toLocaleDateString('en-US', {
		timeZone,
		timeZoneName: 'short',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	});
	// check if any days need to be shifted
	if (shiftDays !== 0) {
		const shiftedDate = new Date(localisedDateString);
		shiftedDate.setDate(shiftedDate.getDate() + shiftDays);
		// Always set the time to UTC midnight. This ensures consistency with our data querying, which uses UTC time.
		// Additionally, this fixes the issue where Today's date wasn't being selected by default on the calendar (previously it was selecting Yesterday's date).

		shiftedDate.setUTCHours(0, 0, 0, 0);
		return shiftedDate;
	}
	const localisedDate = new Date(localisedDateString);
	localisedDate.setUTCHours(0, 0, 0, 0);
	return localisedDate;
};

export function shiftHours(_date: Date | string, shift: number): Date;
export function shiftHours(_date: undefined, shift: number): undefined;
export function shiftHours(_date: Date | string | undefined, shift: number) {
	if (_date == null) return undefined;

	const date = new Date(_date);
	date.setTime(date.getTime() + shift * 60 * 60 * 1000);
	return date;
}

export function getWeekNumber(d: Date) {
	const target = new Date(d.valueOf());
	const dayNr = (d.getDay() + 6) % 7;
	target.setDate(target.getDate() - dayNr + 3);
	const firstThursday = target.valueOf();
	target.setMonth(0, 1);

	if (target.getDay() !== 4) {
		target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
	}

	return 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);
}

export function getShortMonthName(targetDate: Date, locale: string) {
	return new Intl.DateTimeFormat(locale, { month: 'short' }).format(targetDate);
}

export function isSameDate(left: Date, right: Date): boolean {
	return left.toDateString() === right.toDateString();
}

export function isToday(targetDate: Date) {
	return isSameDate(targetDate, new Date());
}

export function isSameWeek(left: Date, right: Date) {
	return getWeekNumber(left) === getWeekNumber(right);
}

export function isYesterday(targetDate: Date) {
	const now = new Date();
	targetDate.setHours(0);
	targetDate.setMinutes(0);
	const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
	return isSameDate(yesterday, targetDate);
}

export function isTomorrow(targetDate: Date) {
	const now = new Date();
	targetDate.setHours(0);
	targetDate.setMinutes(0);
	const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
	return isSameDate(tomorrow, targetDate);
}

export function getDateString(date = new Date()) {
	return [date.getFullYear(), ('00' + (date.getMonth() + 1)).slice(-2), ('00' + date.getDate()).slice(-2)].join('-');
}

function getWeeksLeftLabel(weeksLeft: number) {
	const vm = getVm();
	return weeksLeft === 1
		? vm.$t('WEBAPP_WEEK_LEFT', { weeks: weeksLeft })
		: vm.$t('WEBAPP_WEEKS_LEFT', { weeks: weeksLeft });
}

export function formattedReleaseDate(
	date: string,
	label: UpcomingReleaseLabel,
	language: string,
	dateFormat: 'short' | 'long' = 'short',
	country?: string
): { heading: string; subheading: string | null; exactDateAvailable: boolean } {
	const vm = getVm();
	const dateObject = new Date(date);
	let heading = null;
	let subheading = null;
	let exactDateAvailable = false;

	switch (label) {
		case UpcomingReleaseLabel.Date:
			const weeksLeft = diffInWeeks(dateObject);
			heading = new Intl.DateTimeFormat(`${[language]}${country ? '-' + country : ''}`, {
				dateStyle: dateFormat,
			}).format(dateObject);
			subheading = weeksLeft > 0 ? ' ' + getWeeksLeftLabel(weeksLeft) : '';
			exactDateAvailable = true;
			break;
		case UpcomingReleaseLabel.Month:
			heading = new Intl.DateTimeFormat(`${[language]}${country ? '-' + country : ''}`, { month: 'long' }).format(
				dateObject
			);
			break;
		case UpcomingReleaseLabel.Year:
			heading = new Intl.DateTimeFormat(`${[language]}${country ? '-' + country : ''}`, {
				year: 'numeric',
			}).format(dateObject);
			break;
		case UpcomingReleaseLabel.Today:
			heading = vm.$t('WEBAPP_TODAY');
			break;
		case UpcomingReleaseLabel.Tomorrow:
			heading = vm.$t('WEBAPP_DATE_TOMORROW');
			break;
		case UpcomingReleaseLabel.Autumn:
			heading = vm.$t('WEBAPP_FALL');
			break;
		case UpcomingReleaseLabel.Winter:
			heading = vm.$t('WEBAPP_WINTER');
			break;
		case UpcomingReleaseLabel.Spring:
			heading = vm.$t('WEBAPP_SPRING');
			break;
		case UpcomingReleaseLabel.Summer:
			heading = vm.$t('WEBAPP_SUMMER');
			break;
		default:
			heading = vm.$t('WEBAPP_DATE_UNKNOWN_TBA');
	}

	return {
		heading,
		subheading,
		exactDateAvailable,
	};
}

/**
 * Calculate the amount of time between an end date and an optional start date (end - start).
 * If start is not passed, it defaults to "now", i.e. `new Date()`.
 *
 * @param end Date from which to calculate the time left
 * @param start Starting time of the calculation defaults to now (new Date())
 */
export function calcTimeLeft(end: Date, start: Date = new Date()) {
	const yearsLeft = end.getFullYear() - start.getFullYear();

	return {
		days: diffInDays(end, start),
		weeks: diffInWeeks(end, start),
		months: end.getMonth() - start.getMonth() + 12 * yearsLeft,
		years: yearsLeft,
	};
}

export function diffInWeeks(end: Date, start: Date = new Date()) {
	const msPerHour = 60 * 60 * 1000;
	const msLeft = diffInMilliseconds(end, start);

	return Math.floor(msLeft / (msPerHour * 24 * 7));
}

export function diffInDays(end: Date, start: Date = new Date()) {
	const msPerHour = 60 * 60 * 1000;
	const msLeft = diffInMilliseconds(end, start);

	return Math.floor(msLeft / (msPerHour * 24));
}

export function diffInMilliseconds(end: Date, start: Date = new Date()) {
	return end.getTime() - start.getTime();
}

export function timestamp(
	ISOdatetime: string | number | Date,
	formatOptions: Intl.DateTimeFormatOptions | undefined,
	country: string,
	language: string
) {
	let localeFormat = 'en-GB';

	if (!Intl || typeof Intl.Locale !== 'function' || typeof Intl.DateTimeFormat !== 'function') {
		// fallback for the very rare case of not supporting Intl, without adding a bloated polyfill
		const updated = new Date(ISOdatetime);
		return {
			time: [('00' + updated.getMinutes()).slice(-2), ('00' + updated.getSeconds()).slice(-2)].join(':'),
			date: getDateString(updated),
		};
	}
	if (language && country) {
		const locale = new Intl.Locale(language, {
			region: country,
		});
		localeFormat = locale.baseName;
	} else if (process?.client) {
		localeFormat = window.navigator.language;
	}

	const dateTimeFormat = new Intl.DateTimeFormat(localeFormat, formatOptions);

	const updated = new Date(ISOdatetime);
	const date = dateTimeFormat.format(updated);
	const time = updated.toLocaleTimeString(localeFormat);

	return {
		time,
		date,
	};
}
