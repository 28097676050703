import { AssertByTypename } from '@/helpers/graphql-model-helper';
import pictureCompHelper from '@/helpers/picture-comp-helper';

import { ArticleFragment } from '@/pages/graphql/queries/GetArticleByUrl.query';

export type Node = ArticleFragment['mainObjects'][number]['node'];
export type ArticleObject = AssertByTypename<Node, 'GenericTitleList' | 'Movie' | 'Show' | 'Season'>;

const getArticleBackdrop = (
	mainObject: ArticleObject | null,
	state: any,
	profile: 'backdrop' | 'article_backdrop' = 'article_backdrop'
) => {
	if (!mainObject) return null;

	const backdropUrl =
		mainObject.__typename === 'GenericTitleList'
			? (mainObject.titles.edges ?? [])[0]?.node.content.backdrops[0]?.backdropUrl
			: mainObject.content.backdrops[0]?.backdropUrl;

	if (!backdropUrl) return null;

	return pictureCompHelper.getDefaultImageUrl(backdropUrl, profile, '', state, false);
};

export { getArticleBackdrop };
