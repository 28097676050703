import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';

declare global {
	interface Window {
		snaptr: any;
	}
}

export class TrackingProviderSnapchatPixel extends TrackingProvider {
	dataProcessingService: DataProcessingService | null = DataProcessingService.SNAPCHAT_PIXEL;
	constructor(private config: { pixelId: string; doNotTrack: boolean; email: string }) {
		super();
	}
	initialize(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			if (!this.config.doNotTrack) {
				let u: any, r: any;
				if (window.snaptr) return;
				let a: any = (window.snaptr = function () {
					a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments);
				});

				a.queue = [];
				let s = 'script';
				r = document.createElement(s);
				r.async = !0;
				r.src = 'https://sc-static.net/scevent.min.js';
				u = document.getElementsByTagName(s)[0];
				u.parentNode?.insertBefore(r, u);
				window.snaptr('init', this.config.pixelId, {
					user_email: this.config.email,
				});
			}
			resolve();
		});
	}
	async trackPage({ path, title }: { path?: string; title?: string }) {
		if (!this.config.doNotTrack) {
			window.snaptr('track', 'PAGE_VIEW');
		}
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {}
}
