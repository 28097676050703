import { getDateString } from '@/helpers/date-helper';
import { TrafficHelper } from '@/helpers/traffic-helper';
import { NewResponse } from '@/interfaces/responses/new';
import { TitleListName } from '@/interfaces/responses/title-list';
import { DailySpecials } from '@/interfaces/specials';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

const state = () => ({
	specials: {} as Record<string, DailySpecials>,
	initialTitleLists: {} as any,
});

type State = ReturnType<typeof state>;

const getters: GetterTree<State, any> = {
	// active dates according to the loaded days in the new timeline.
	// this is used to better calculate when a special appear according to their `duration` field.
	dates: (state, getters, rootState): string[] => {
		return (rootState.title.lists.new as NewResponse).days
			.map(date => date.date)
			.filter((item, index, array) => array.indexOf(item) === index);
	},
	specials: (state, getters, rootState) => {
		return Object.keys(state.specials).reduce((specials, date) => {
			if (TrafficHelper.isSeoTraffic()) {
				return {};
			}
			const platform = 'web';
			const eligibleItems = state.specials[date].items
				.filter(item => item.platform_enabled[platform]) // @todo for mobile
				.filter(item => !item.survey_id || !(item.survey_id in rootState.user.seenSurveys)) // seen survey special
				.filter(item => (rootState.user.settings.hidden_timeline_specials || []).indexOf(item.name) === -1); // remove specials that are hidden

			// if any items have duration: push the whole DailySpecial forward
			const duration = Math.max(...eligibleItems.map(item => item.duration || 0));
			const from = new Date(date);
			const till = new Date(date);
			till.setDate(new Date(date).getDate() + duration);
			till.getTime();

			const targetDateRaw = getters.dates
				.sort()
				.map((d: string) => new Date(d))
				.reduce((root: Date, item: Date) => {
					return item >= from && item <= till ? item : root;
					// check if current special day is in the range of date and date + duration
					// and then take the youngest date that exist on the timeline dates.
				}, new Date(date));
			const targetDate = getDateString(targetDateRaw);

			if (eligibleItems.length > 0 && targetDate) {
				specials[targetDate] = {
					...state.specials[date],
					items: eligibleItems,
				};
			}
			return specials;
		}, {} as Record<string, DailySpecials>);
	},
	dailySpecial: (state, getters) => (date: string) => {
		return getters.specials[date];
	},
};

const actions: ActionTree<State, any> = {};

const mutations: MutationTree<State> = {
	SET_SPECIALS(state, specials: Record<string, DailySpecials>) {
		state.specials = specials;
	},
	SET_INITIAL_TITLE_LIST_STATE(state, titleListState: any) {
		state.initialTitleLists = {
			[TitleListName.LIKELIST]: titleListState[TitleListName.LIKELIST],
			[TitleListName.DISLIKELIST]: titleListState[TitleListName.DISLIKELIST],
		};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
