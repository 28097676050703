/**
 * Lazy loaded components
 *
 * We want to minimized the size of the JS delivered to the client
 * Every component that:
 * 1) has a substantial set of conditional child components
 * 2) it's very big (should implement point 1)
 *
 * will need to use dynamic imports
 * If the sub components is shared among other components then it needs to
 * be put here and imported
 */

// Pages
export const CuratedList = () => import(/* webpackChunkName: "curated-list" */ '@/pages/CuratedList.vue');
export const Home = () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue');
export const New = () => import(/* webpackChunkName: "new" */ '@/pages/New.vue');
export const Upcoming = () => import(/* webpackChunkName: "upcoming" */ '@/pages/Upcoming.vue');
export const PlotRecommender = () =>
	import(/* webpackChunkName: "lp-plot-recommender" */ '@/pages/landing-pages/plot-recommender/PlotRecommender.vue');
export const Popular = () => import(/* webpackChunkName: "popular" */ '@/pages/Popular.vue');
export const RefreshToken = () => import(/* webpackChunkName: "refresh-token" */ '@/pages/RefreshToken.vue');
export const Search = () => import(/* webpackChunkName: "search" */ '@/pages/Search.vue');
export const Signout = () => import(/* webpackChunkName: "signout" */ '@/pages/Signout.vue');
export const StaticPage = () => import(/* webpackChunkName: "static" */ '@/pages/StaticPage.vue');
export const StreamGreen = () =>
	import(/* webpackChunkName: "lp-streamgreen" */ '@/pages/landing-pages/stream-green/StreamGreen.vue');
export const SubscriberForm = () =>
	import(/* webpackChunkName: "lp-subscriber-form" */ '@/pages/landing-pages/subscriber-form/SubscriberForm.vue');
export const Talent = () => import(/* webpackChunkName: "talent" */ '@/pages/Talent.vue');
export const TastyAds = () =>
	import(/* webpackChunkName: "tasty-ads" */ '@/pages/landing-pages/tasty-ads/TastyAds.vue');
export const TitleDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/TitleDetail.vue');
export const TvRegistration = () => import(/* webpackChunkName: "tv-registration" */ '@/pages/TvRegistration.vue');
export const Watchlist = () => import(/* webpackChunkName: "watchlist" */ '@/pages/Watchlist.vue');
export const WhatCulture = () =>
	import(/* webpackChunkName: "whatculture" */ '@/pages/landing-pages/whatculture/WhatCulture.vue');
export const Oscars2023 = () =>
	import(/* webpackChunkName: "oscars2023" */ '@/pages/landing-pages/oscars-2023/Oscars2023.vue');
export const Valentine2023 = () =>
	import(/* webpackChunkName: "Valentine2023" */ '@/pages/landing-pages/valentine-2023/Valentine2023.vue');
export const CollegesMostSuperBowlPlayers = () =>
	import(
		/* webpackChunkName: "collegesmostsuperbowlplayers" */ '@/pages/landing-pages/sports/CollegesMostSuperBowlPlayers.vue'
	);
export const ExpansionSeasonsMLS = () =>
	import(/* webpackChunkName: "expansionseasonmls" */ '@/pages/landing-pages/sports/ExpansionSeasonsMLS.vue');
export const NBAPlayoffs = () =>
	import(/* webpackChunkName: "nbaplayoffs" */ '@/pages/landing-pages/sports/NBAPlayoffs.vue');
export const StreamingCharts = () =>
	import(/* webpackChunkName: "streaming-charts" */ '@/pages/landing-pages/streaming-charts/StreamingCharts.vue');
export const StatisticPage = () =>
	import(/* webpackChunkName: "statistics-page" */ '@/pages/landing-pages/statistics/StatisticPage.vue');
export const GlobalPress = () => import(/* webpackChunkName: "global-press" */ '@/pages/global-pages/GlobalPress.vue');

// Title List
export const TitleListREST = () => import(/* webpackChunkName: "title-list" */ '@/components/list/TitleListREST.vue');
export const TitleListGraphql = () =>
	import(/* webpackChunkName: "title-list" */ '@/components/list/TitleListGraphql.vue');

// Modals
export const AccountModal = () =>
	import(/* webpackChunkName: "account-modal" */ '@/components/modals/account/AccountModal.vue');
export const LoginManager = () =>
	import(/* webpackChunkName: "login-manager-modal" */ '@/components/login-manager/LoginManager.vue');
export const CountrySelectionModal = () =>
	import(/* webpackChunkName: "country-selection-modal" */ '@/components/mobile/CountrySelectionModal.vue');
export const ProModal = () => import(/* webpackChunkName: "pro-modal" */ '@/components/modals/pro/ProModal.vue');

export const CancelProModal = () =>
	import(/* webpackChunkName: "cancel-pro-modal" */ '@/components/modals/pro/CancelProModal.vue');
export const DebugModal = () => import(/* webpackChunkName: "debug-modal" */ '@/components/modals/DebugModal.vue');
export const LanguageSelectionModal = () =>
	import(/* webpackChunkName: "language-selection-modal" */ '@/components/mobile/LanguageSelectionModal.vue');
export const LoginMessageModal = () =>
	import(/* webpackChunkName: "login-message-modal" */ '@/components/modals/login/LoginMessageModal.vue');
export const PackagesSelectionModal = () =>
	import(
		/* webpackChunkName: "provider-selection-modal" */ '@/components/modals/package-selection/PackagesSelectionModal.vue'
	);
export const FilterProductionCountryMoreModal = () =>
	import('@/components/modals/FilterProductionCountryMoreModal.vue');

export const SettingsModal = () =>
	import(/* webpackChunkName: "settings-modal" */ '@/components/modals/SettingsModal.vue');
export const CountryList = () =>
	import(/* webpackChunkName: "country-list" */ '@/components/modals/country-list/CountryList.vue');
export const LanguageList = () =>
	import(/* webpackChunkName: "language-list" */ '@/components/modals/account/language-list/LanguageList.vue');

// Components
export const FilterBar = () => import(/* webpackChunkName: "filter-bar" */ '@/components/filter-bar/FilterBar.vue');
export const YoutubePlayer = () => import(/* webpackChunkName: "youtube-player" */ '@/ui-components/YoutubePlayer.vue');
export const YoutubePlayerGraphql = () =>
	import(/* webpackChunkName: "youtube-player" */ '@/ui-components/YoutubePlayerGraphql.vue');
export const VideoJsPlayer = () => import(/* webpackChunkName: "videojs-player" */ '@/ui-components/VideoJsPlayer.vue');
export const TrailerModal = () => import(/* webpackChunkName: "trailer-modal" */ '@/components/TrailerModal.vue');

// Filters
export const FilterProductionCountry = () =>
	import(/* webpackChunkName: "premium-filters" */ '@/components/filter-bar/FilterProductionCountry.vue');
export const FilterRuntime = () =>
	import(/* webpackChunkName: "premium-filters" */ '@/components/filter-bar/FilterRuntime.vue');
export const FilterVoteCount = () =>
	import(/* webpackChunkName: "premium-filters" */ '@/components/filter-bar/FilterVoteCount.vue');

// Misc
export const TrialBox = () => import(/* webpackChunkName: "trial-box" */ '@/components/trial-box/TrialBox.vue');
