
import Vue from 'vue';
import { Component, Emit, InjectReactive, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';

const FreemiumFilterBadge = () =>
	import(/* webpackChunkName: "freemium-filter-badge" */ '@/components/filter-bar/FreemiumFilterBadge.vue');

import LoginMenuButton from '@/components/nav/LoginMenuButton.vue';
import LoginMenuButtonNew from '@/components/nav/LoginMenuButtonNew.vue';
import SettingsButton from '@/components/nav/static-pages/SettingsButton.vue';
import SearchSuggesterBar from '@/components/search/SearchSuggesterBar.vue';

import { CollectionType } from '@/enums/collection-type';
import { OnboardingStatus } from '@/enums/onboarding-status';
import { isSportSupported } from '@/enums/sports';
import type { WebLocale } from '@/enums/web-locale';

import Provider from '@/interfaces/provider';
import { HtmlContentMovieDetails, UrlMetadataResponse } from '@/interfaces/responses/url-metadata';

import { GenresByShortNameConstant } from '@/constants/ConstantsProvider.vue';
import { Genres, Subgenre } from '@/constants/types';
import { getRouteName, getUrlParamsFromFilterState } from '@/helpers/state-helper';
import { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { isEditorialLocale } from '@/routing/editorial';
import type { FilterCollection } from '@/stores/filter.store';
import { ListsCollectionType } from '@/stores/routing.store';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const Constant = namespace('constant');
const Filter = namespace('filter');
const Language = namespace('language');
const Routing = namespace('routing');
const SearchSuggester = namespace('searchSuggester');
const User = namespace('user');

@Component({
	name: 'AppHeader',
	components: {
		FreemiumFilterBadge,
		LoginMenuButton,
		LoginMenuButtonNew,
		SearchSuggesterBar,
		SettingsButton,
	},
})
export default class AppHeader extends Vue {
	CollectionType = CollectionType;

	@Prop({ required: true }) lastActiveCollectionType: null | CollectionType;
	@Prop({ required: true }) locale: string;

	@InjectReactive({ from: GenresByShortNameConstant, default: [] }) readonly genresByShortName: Genres;

	@Constant.Getter subgenresList: Subgenre[];
	@Constant.Getter providersByShortName: Record<string, Provider>;

	@Filter.Getter currentFilters: FilterCollection;
	@Filter.Getter filters: (collectionType: CollectionType) => FilterCollection;

	@Language.Getter country: string;
	@Language.State detectedWebLocale: WebLocale;
	@Language.State webLocale: WebLocale;

	@Routing.Getter urlMetadata: UrlMetadataResponse<HtmlContentMovieDetails>;
	@Routing.State activeRoute: any;
	@Routing.State lastRoute: Route;
	@Routing.State urlMetadataPromise: any;
	@Routing.State lastActiveListsCollectionType: ListsCollectionType | null;

	@SearchSuggester.Getter isSearchSuggesterOpen: boolean;
	@SearchSuggester.Action setSearchSuggesterVisibility: (value: boolean) => void;

	@User.State onboardingStatus: OnboardingStatus;
	@User.Getter isLoggedIn: () => boolean;
	@User.Getter isPremium: boolean;

	get userLoggedIn(): boolean {
		return this.isLoggedIn();
	}

	get isNewTab() {
		const newPageRoutes = ['app.titles.new', 'app.titles.upcoming', 'app.titles.pricedrops'];
		return newPageRoutes.find(route => (this.$route.name || '').startsWith(route));
	}

	get isNetflixStatisticPage(): boolean {
		return this.$route.name === 'app.landingpage.statistics';
	}

	get isHome(): boolean {
		return this.$route.name === 'app.home';
	}

	get isGlobal(): boolean {
		return this.onboardingStatus === OnboardingStatus.GLOBAL;
	}

	get isUnsupportedCountry(): boolean {
		return this.onboardingStatus === OnboardingStatus.UNSUPPORTED_COUNTRY;
	}

	get isStreamingCharts() {
		return this.$route.name === 'app.streaming-charts';
	}

	get logoSrc() {
		const file = `JustWatch-logo-${this.isGlobal ? 'large' : 'small'}`;
		return {
			png: `/${ASSETS_DIR}/img/logo/${file}.png`,
			webp: `/${ASSETS_DIR}/img/logo/${file}.webp`,
		};
	}

	get hasSports() {
		return isSportSupported(this.webLocale);
	}

	get isValentineList() {
		return (
			this.isValentine &&
			['US', 'GB', 'CA', 'AU', 'FR', 'DE', 'BR', 'IT', 'TR', 'IN', 'AR', 'ES'].includes(this.country)
		);
	}

	get valentineListId() {
		const valentineListCountryMapping = {
			US: 'tl-tu-3437680c-6645-4fe9-ae45-539c501fb016',
			GB: 'tl-tu-b3acebef-ca21-4314-ad24-7bcd8da6c50b',
			CA: 'tl-tu-44842902-27f3-4a9f-975c-9aafa906238a',
			AU: 'tl-tu-3e635587-6c35-4df1-8578-cdec18a0a3f2',
			FR: 'tl-tu-99220a66-b33d-4d16-bd90-4662a50ea6d6',
			DE: 'tl-tu-1b893f89-0b95-418c-9ee1-3e5acefd5b9c',
			BR: 'tl-tu-4b77ddad-768a-4e23-9c0f-ec15856f82e7',
			IT: 'tl-tu-3fcfc9a0-4916-490b-9ef8-bdc031d08f01',
			TR: 'tl-tu-8d7aa713-1f88-401c-9090-225d3205d5fa',
			IN: 'tl-tu-54933703-0f0c-4f6c-9a4d-15b15bc7ae3e',
			AR: 'tl-tu-870b6894-64af-44ed-a14b-162754d3810f',
			ES: 'tl-tu-6aa065cb-90ab-4001-bac9-ca5aae4acbbc',
		};
		return valentineListCountryMapping[this.country as keyof typeof valentineListCountryMapping];
	}

	get isValentine() {
		return new Date().getDate() === 14 && new Date().getMonth() === 1;
	}

	get isGuideLocale() {
		return isEditorialLocale(this.webLocale);
	}

	get listsCollectionTypeToNavigate() {
		if (this.lastActiveListsCollectionType) {
			return this.lastActiveListsCollectionType;
		}

		return this.isLoggedIn() ? CollectionType.MY_LISTS : CollectionType.PUBLIC_LISTS;
	}

	/**
	 * with these parameters we can always link to the correct url for each collectionType page.
	 * in some cases we want to overwrite the currentFilters of the passed CollectionType, that's what `filterOverwrite` handles.
	 */
	routeParams(collectionType: CollectionType, filterOverwrite?: FilterCollection): any {
		const isNewPageCollectionType = [
			CollectionType.COMING_SOON,
			CollectionType.LEAVING_SOON,
			CollectionType.PRICEDROPS,
		].includes(collectionType);

		const filterCollectionType = isNewPageCollectionType ? CollectionType.NEW : collectionType;
		const filters = filterOverwrite ?? this.filters(filterCollectionType);
		const routeName = getRouteName(collectionType, filters, this.subgenresList);
		const params = getUrlParamsFromFilterState(
			routeName,
			filters,
			this.providersByShortName,
			this.genresByShortName,
			collectionType,
			this.$route.query,
			this.subgenresList
		);

		// Note: list_id query param needs to be deleted when navigating to the Lists tab root level, since we are
		// using it on Lists tab to determinate if list details page is opened
		if (params.query.list_id) {
			delete params.query.list_id;
		}

		return { name: routeName, ...params };
	}

	@Emit('tabChange')
	async onTabChange(newTab: string) {
		await this.urlMetadataPromise;
		const pageTypeContext = await TrackingHelper.getPageTypeContext(this.lastRoute.path, true);
		this.trackEvent('tabchange', newTab, [pageTypeContext]);
		return newTab;
	}

	trackEvent(action: string, label?: string, contexts: SnowplowContext[] = []) {
		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action,
				label,
			},
			contexts
		);
		if (label === 'home') {
			const onboardingStatus =
				this.onboardingStatus === OnboardingStatus.TASTE_ONBOARDING
					? OnboardingStatus.GLOBAL
					: this.onboardingStatus;

			TrackingHelper.trackEvent('home_page_view', {
				action: onboardingStatus,
				property: this.detectedWebLocale || this.webLocale,
			});
		}

		//TITLE_DETAIL_REDESIGN
		trackTitleDetailRedesignNewBuyBoxExp(
			{ action: 'click', label: action, property: label },
			action !== 'click_logo'
		);
		//TITLE_DETAIL_REDESIGN
	}

	onOverlayClick() {
		this.setSearchSuggesterVisibility(false);
	}
}
