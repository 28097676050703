import { getAppVersion } from '@/graphql/utils/links';
import { getAxiosCached } from './axios-helper';
import type { DocumentNode } from 'apollo-link';

async function headerfulCachedRequest(query: string | DocumentNode, variables: any, jwSession: string, jwId: string) {
	try {
		const { X_RATE_LIMIT_SHORTCUT } = process.env;
		const clientOnlyHeaders = {
			...(X_RATE_LIMIT_SHORTCUT ? { 'X-Rate-Limit-Shortcut': X_RATE_LIMIT_SHORTCUT } : {}),
			...(jwSession ? { 'Jw-Session': jwSession } : {}),
			...(jwId ? { 'Device-Id': jwId } : {}),
		};
		const headers = {
			'content-type': 'application/json',
			'App-Version': getAppVersion(),
			// only add client headers if client is doing the request (SPA).
			// otherwise we could run in caching problems, as the headers are also taken into account for cache keys.
			...(process.server ? {} : clientOnlyHeaders),
		};

		const res = await (
			await getAxiosCached()
		).request({
			method: 'POST',
			url: JW_CONFIG.GRAPHQL_URL,
			headers,
			data: { query: getQuery(query), variables },
		});
		return res.data;
	} catch (err) {
		const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
		captureMessageForSentry('core cached request failed', { err, where: 'request-helper.ts' }, 'error');
	}
}

function getQuery(query: string | DocumentNode): string {
	if ((query as DocumentNode).loc) {
		return (query as DocumentNode).loc?.source.body.replace(/[\s,]+/g, ' ').trim() || '';
	}
	return query as string;
}

export { headerfulCachedRequest };
