import type { SnowplowContext, SnowplowSchema } from '../providers';

/**
 * Use context schema name to avoid duplicate types
 * Usage: as argument to Array.filter() method
 */
const removeDuplicateByType = (baseList: SnowplowSchema[]) => (element: SnowplowContext) => {
	const type = element.getSchema();
	return !baseList.includes(type);
};

export { removeDuplicateByType };
