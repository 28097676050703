import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';

import { CollectionType } from '@/enums/collection-type';
import type { WebLocale } from '@/enums/web-locale';

import { Home, RefreshToken, Signout, StaticPage } from '@/lazy-load-components';

import { PageType, makeAppRoutes } from '@/routing/config';
import { makeEditorialRoutes } from '@/routing/editorial';
import { makeSportRoutes } from '@/routing/sports';
import { makeRedirectRoutes } from '@/routing/redirect';

Vue.use(VueRouter);

function createRoutes(webLocale: WebLocale, store: Store<any>): RouteConfig[] {
	return [
		// default root route for WEBAPP
		{
			name: 'app.home',
			path: '/',
			component: Home,
			beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
				await Promise.all([
					// sport translations
					store.dispatch('language/fetchSportsTranslation'),
					// locales that are used heavily in timezone calculations
					store.dispatch('language/loadLocales'),
				]);
				next();
			},
			meta: {
				collectionType: CollectionType.GLOBAL,
				hasFilterBar: false,
				initialRouteState: {},
				contexts: [],
				tab: 'home',
				pageType: PageType.HOME,
			},
		},

		// Refresh Token
		{
			name: 'app.refreshtoken',
			path: '/refresh',
			component: RefreshToken,
			beforeEnter: async (to, from, next) => {
				if (process.client) {
					await store.dispatch('user/refreshToken');
				}
				next();
			},
		},

		// Signout
		{
			name: 'app.signout',
			path: '/signout',
			component: Signout,
			beforeEnter: async (to, from, next) => {
				await store.dispatch('user/logout');
				next();
			},
		},

		// pseudonym-ID redirect
		{
			name: 'app.pseudonymId',
			path: '/pseudonym-ID',
			meta: {
				hasFilterBar: false,
			},
			redirect: to => {
				return `${webLocale}${to.path}`;
			},
		},

		// tv registration redirect to local
		{
			name: 'app.tv',
			path: '/tv',
			meta: {
				hasFilterBar: false,
			},
			redirect: to => {
				return `${webLocale}${to.path}`;
			},
		},

		// tv registration redirect to local
		{
			name: 'app.tv.code',
			path: '/tv/:code',
			props: true,
			meta: {
				hasFilterBar: false,
			},
			redirect: to => {
				return `${webLocale}${to.path}`;
			},
		},

		// Privacy Policy global to locale redirect
		{
			name: 'app.privacy.global',
			path: '/privacy-policy',
			meta: {
				hasFilterBar: false,
			},
			redirect: to => {
				// country specific slugs
				const countryMap: any = {
					hk: '私隱政策',
					tw: '私隱政策',
				};

				if (countryMap[webLocale]) {
					return `${webLocale}/${countryMap[webLocale]}`;
				}

				return `${webLocale}${to.path}`;
			},
		},

		// Privacy Policy Zendesk redirect
		{
			name: 'app.privacy.zendesk',
			path: '/*/privacy-policy', // e.g. /us/privacy-policy
			beforeEnter(to) {
				if (process.client) {
					location.href = `https://www.${JW_CONFIG.DOMAIN}${to.fullPath}`;
					return;
				}
			},
			meta: {
				reload: true,
			},
		},

		// Terms Zendesk redirect
		{
			name: 'app.terms.zendesk',
			path: '/*/terms',
			beforeEnter(to) {
				if (process.client) {
					location.href = `https://www.${JW_CONFIG.DOMAIN}${to.fullPath}`;
					return;
				}
			},
			meta: {
				reload: true,
			},
		},

		// Application Routes
		...makeAppRoutes(webLocale, store),

		// Redirect Routes
		...makeRedirectRoutes(webLocale),

		// SPORTS
		...makeSportRoutes(webLocale, store),

		// EDITORIAL
		...makeEditorialRoutes(webLocale),

		// FAQ
		{
			name: 'app.faq',
			path: '/*/faq', // e.g. /us/faq
			beforeEnter(to) {
				if (process.client) {
					location.href = `https://www.${JW_CONFIG.DOMAIN}${to.fullPath}`;
					return;
				}
			},
			meta: {
				reload: true,
			},
		},

		// STATIC PAGES @todo
		{
			name: 'app.static.content',
			path: '/*/*', // e.g. /us/about
			component: StaticPage,
			meta: {
				tab: 'static',
				collectionType: CollectionType.TITLELIST, // only used for titlelist pages
			},
		},
	];
}

function createRouter(webLocale: WebLocale, store: Store<any>) {
	return new VueRouter({
		mode: 'history',
		base: process.env.BASE_URL,
		routes: createRoutes(webLocale, store),
		scrollBehavior: (to, _from, savedPosition) => {
			const { scrollPositions } = store.state.routing;
			// the sports tab has several children pages that should keep scroll position independently.
			// each sports route has a meta.data identifier which will be used to keep the scroll position distinct for each child page.
			const tab = to?.meta?.tab === 'sports' ? `${to?.meta?.tab}-${to?.meta?.data}` : to?.meta?.tab;
			// sports event is pretty much the equivalent to the detail page.
			// thus its scroll position needs to be reset every time it gets looked up.
			if (tab === 'detail' || tab === 'editorial' || to?.name === 'app.sports.event') {
				return { x: 0, y: 0 };
			} else if (scrollPositions[tab]) {
				return { x: 0, y: scrollPositions[tab] };
			} else if (savedPosition) {
				return savedPosition;
			}

			return { x: 0, y: 0 };
		},
	});
}

VueRouter.prototype.changeLocale = async function (locale: WebLocale, store: Store<any>) {
	this.matcher = createRouter(locale, store).matcher;
};

export { createRouter };
