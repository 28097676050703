import type { Consent, UserSettings } from '@/stores/user.store';
import { CONSENT_PARTNERS } from '@/stores/user.store';

/**
 * returns whether a user is set for doNotTrack.
 * through given or withdrawn consent AND browser/OS specific do-not-track options.
 * filteredConsents is optional, is useful for trackers like facebook pixel that don't care if only google consent has been withdrawn, but not facebook's one.
 */
const hasDoNotTrack = (
	deviceDoNotTrack: boolean,
	consents: UserSettings['jw_consents'] | {} = {},
	filteredConsents?: (keyof UserSettings['jw_consents'])[]
): boolean => {
	const consentKeys = filteredConsents || (consents ? Object.keys(consents) : []);
	const hasRejectedAnyConsent = consentKeys.some(key => (consents as any)[key] === false);
	return deviceDoNotTrack || hasRejectedAnyConsent;
};

/**
 * Fills consents object with data for all consent partners.
 */
const fillConsents = (
	consents: Partial<Record<Consent, boolean | null>>,
	defaultValue: boolean
): Record<Consent, boolean> => {
	const result = {} as Record<Consent, boolean>;
	(Object.keys(CONSENT_PARTNERS) as Consent[]).forEach(consent => {
		const consentValue = consents[consent];
		// Default emails and terms_and_conditions consent to false because those would be collected by the registration form
		if (defaultValue && (consent === 'emails' || consent === 'terms_and_conditions')) {
			result[consent] = consentValue || false;
			return;
		}

		if (typeof consentValue === 'boolean') {
			result[consent] = consentValue;
			return;
		}
		result[consent] = defaultValue;
	});
	return result;
};

/**
 * checks if that user has any consent given, true or false.
 * important: only use this for non-UC countries!
 */
const hasAnyConsentsGiven = (consents?: Record<Consent, boolean | null> | null) => {
	if (consents == null) return false;
	const filteredConsents = [];
	for (const key in consents) {
		if (!['emails', 'terms_and_conditions'].includes(key)) {
			filteredConsents.push(consents[key as Consent]);
		}
	}
	return filteredConsents.some(consent => consent !== null);
};

export { fillConsents, hasAnyConsentsGiven, hasDoNotTrack };
