import { COUNTRY_EXCEPTIONS, WEB_LOCALES } from '@/constants/web-locales.constant';

import { OnboardingStatus } from '@/enums/onboarding-status';
import type { WebLocale } from '@/enums/web-locale';

import { getVm } from '@/helpers/vm-helper';
import { TasteSurveyType } from '@/stores/user.store';
import type { Store } from 'vuex';

/**
 * @param {WebLocale} detectedWebLocale WebLocale
 * @param {boolean} tasteSurveyCompleted boolean
 * @param {boolean} isLoggedIn boolean
 * @param {boolean} tasteSurveyType TasteSurveyType
 * @returns {OnboardingStatus} OnboardingStatus
 */
const getOnboardingStatus = (
	detectedWebLocale: WebLocale,
	tasteSurveyCompleted: boolean,
	isLoggedIn: boolean,
	pathname: string,
	tasteSurveyType?: TasteSurveyType
): OnboardingStatus => {
	// Default to GLOBAL
	let status: OnboardingStatus = OnboardingStatus.GLOBAL;
	const isHomeUrl = pathname === '/';

	if (isHomeUrl && !isSupportedCountry(detectedWebLocale)) {
		status = OnboardingStatus.UNSUPPORTED_COUNTRY;
	} else if (detectedWebLocale && !tasteSurveyCompleted && !isLoggedIn) {
		// Note(valerio.mazza): this obuiquity of GLOBAL_CLICKED_CTA to be both a test survey type and onboarding status needs to be refactored
		const isProperSurveyType = tasteSurveyType === TasteSurveyType.GLOBAL_CLICKED_CTA;

		if (isProperSurveyType) {
			status = OnboardingStatus.GLOBAL_CLICKED_CTA;
		} else {
			status = OnboardingStatus.GLOBAL;
		}
	} else if (tasteSurveyCompleted && !isLoggedIn) {
		status = OnboardingStatus.ONBOARDED;
	} else if (!tasteSurveyCompleted && isLoggedIn) {
		status = OnboardingStatus.SIGNED_UP;
	} else if (detectedWebLocale && tasteSurveyCompleted && isLoggedIn) {
		status = OnboardingStatus.USER;
	}

	return status;
};

const isSupportedCountry = (detectedWebLocale: WebLocale) => {
	const availableCountries: string[] = (Object.keys(WEB_LOCALES) as WebLocale[]).map(webLocale =>
		WEB_LOCALES[webLocale].substr(-2).toLowerCase()
	);

	const isCountrySupported = availableCountries.includes(detectedWebLocale);
	const isCountryException = Object.values(COUNTRY_EXCEPTIONS).includes(detectedWebLocale);

	return isCountrySupported || isCountryException;
};

/**
 * Start Taste Survey by setting onboarding status to TASTE_ONBOARDING and navigate app.home
 */
const startSurvey = async (shouldExperimentRun = true) => {
	const vm = getVm();

	vm.$store.dispatch('user/saveOnboardingStatus', OnboardingStatus.TASTE_ONBOARDING);
	const { name } = vm.$store.state.routing.activeRoute;
	if (!name.includes('app.home')) {
		vm.$router.push({ name: 'app.home' }).catch();
	}
};

const loadOnBoardingStatus = async (
	store: Store<any>,
	webLocale: WebLocale,
	url: string,
	checkSurvey: boolean = false
) => {
	const isLoggedIn = store.getters['user/isLoggedIn']();
	const { getOnboardingStatus } = await import('@/helpers/onboarding-helper');
	const onboardingStatus = getOnboardingStatus(
		webLocale,
		store.state.user.settings.taste_survey_completed,
		isLoggedIn,
		url,
		checkSurvey ? store.state.user.settings.taste_survey_type : undefined
	);

	store.dispatch('user/saveOnboardingStatus', onboardingStatus);
};

export { getOnboardingStatus, loadOnBoardingStatus, startSurvey };
