
import { Component, Provide, ProvideReactive, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import gql from 'graphql-tag';

/** Use this symbol to Inject and Provide custom lists count. */
export const CustomListsCountKey = Symbol('CustomListsCount');
/** Use this symbol to Refetch custom lists count query. */
export const CustomListsCountRefetchKey = Symbol('CustomListsCountRefetchKey');

/** Use this symbol to Inject and Provide followed lists count. */
export const FollowedListsCountKey = Symbol('FollowedListsCountKey');
/** Use this symbol to Refetch followed lists count query. */
export const FollowedListsCountRefetchKey = Symbol('FollowedListsCountRefetchKey');

/*********************/
const User = namespace('user');

@Component({
	name: 'ListsCountProvider',
	apollo: {
		customListsCount: {
			query: gql`
				query GenericTitleLists($type: GenericTitleListsType = CUSTOM_LISTS) {
					genericTitleLists(type: $type) {
						totalCount
					}
				}
			`,
			fetchPolicy: 'network-only',
			skip() {
				// only logged-in users can have custom lists
				return !this.isLoggedIn();
			},
			update: data => {
				return data.genericTitleLists.totalCount || 0;
			},
		},
		followedListsCount: {
			query: gql`
				query GenericTitleLists($type: GenericTitleListsType = FOLLOWED_LISTS) {
					genericTitleLists(type: $type) {
						totalCount
					}
				}
			`,
			fetchPolicy: 'network-only',
			skip() {
				// only logged-in users can have followed lists
				return !this.isLoggedIn();
			},
			update: data => {
				return data.genericTitleLists.totalCount || 0;
			},
		},
	},
})
export default class ListsCountProvider extends Vue {
	@User.Getter isLoggedIn: () => boolean;

	@ProvideReactive(CustomListsCountKey)
	customListsCount: number = 0;

	@Provide(CustomListsCountRefetchKey)
	customListsCountRefetchQuery() {
		this.$apollo.queries.customListsCount.refetch();
	}

	@ProvideReactive(FollowedListsCountKey)
	followedListsCount: number = 0;

	@Provide(FollowedListsCountRefetchKey)
	followedListsCountRefetchQuery() {
		this.$apollo.queries.followedListsCount.refetch();
	}
}
