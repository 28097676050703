import { computed } from 'vue';

import { useUserStore } from '@/helpers/composables/useStores';
import { useQuery } from '@/helpers/composables/useApollo';

import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { GetUserStatsDocument, GetUserStatsQuery } from '@/graphql/queries/GetUserStats.query';
import type { DeclareComposableReturns } from '@/helpers/composables/types';

export type DeclareUserStats = DeclareComposableReturns<typeof useUserStats>;

export function useUserStats() {
	const { jwId, isPremium, setPremiumStatus } = useUserStore();

	const { onError, onResult, result, refetch, loading } = useQuery<GetUserStatsQuery>(
		GetUserStatsDocument,
		null,
		() => ({
			errorPolicy: 'all',
			enabled: jwId.value != null,
		})
	);

	onError(error =>
		captureMessageForSentry(
			'[GraphQL Get User Stats Data]:',
			{ error: error, where: 'Composable: useUserStats' },
			'error'
		)
	);

	onResult(({ data }) => {
		if (data?.stats) setPremiumStatus(data.stats.hasJWProSubscription);
	});

	const userStats = computed<GetUserStatsQuery['stats'] | undefined>(() => {
		if (!jwId.value || result.value?.stats == null) return undefined;
		return result.value.stats;
	});

	const maxCustomListsPro = computed(() => userStats.value?.maxCustomListsPro ?? 100);
	const maxCustomListsDefault = computed(() => userStats.value?.maxCustomListsDefault ?? 2);
	const customListsLimit = computed(() => (isPremium.value ? maxCustomListsPro.value : maxCustomListsDefault.value));

	const hasJWProSubscription = computed(() => userStats.value?.hasJWProSubscription ?? false);
	const knowsAboutTitleLists = computed(() => userStats.value?.knowsAboutTitleLists ?? false);

	const permanentAudiences = computed(() => userStats.value?.permanentAudiences ?? []);

	return {
		maxCustomListsPro,
		maxCustomListsDefault,
		customListsLimit,
		hasJWProSubscription,
		knowsAboutTitleLists,
		permanentAudiences,
		refetchUserStats: refetch,
		loading,
	};
}
