import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';

declare global {
	interface Window {
		ttq: any;
		TiktokAnalyticsObject: string;
	}
}

export class TrackingProviderTiktokPixel extends TrackingProvider {
	dataProcessingService = DataProcessingService.TIKTOK;

	constructor(private config: { pixelId: string; doNotTrack: boolean }) {
		super();
	}

	initialize() {
		return new Promise<void>((resolve, reject) => {
			if (!this.config.doNotTrack) {
				window.TiktokAnalyticsObject = 'ttq';
				var ttq = (window['ttq'] = window['ttq'] || []);
				ttq.methods = [
					'page',
					'track',
					'identify',
					'instances',
					'debug',
					'on',
					'off',
					'once',
					'ready',
					'alias',
					'group',
					'enableCookie',
					'disableCookie',
				];
				ttq.setAndDefer = function (t: any[], e: any) {
					t[e] = function () {
						t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
					};
				};
				for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
				ttq.instance = function (t: any) {
					for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
						ttq.setAndDefer(e, ttq.methods[n]);
					return e;
				};
				ttq.load = function (e: any, n: any) {
					var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
					(ttq._i = ttq._i || {}),
						(ttq._i[e] = []),
						(ttq._i[e]._u = i),
						(ttq._t = ttq._t || {}),
						(ttq._t[e] = +new Date()),
						(ttq._o = ttq._o || {}),
						(ttq._o[e] = n || {});
					var o = document.createElement('script');
					(o.type = 'text/javascript'), (o.async = !0), (o.src = i + '?sdkid=' + e + '&lib=ttq');
					var a = document.getElementsByTagName('script')[0];
					a.parentNode!.insertBefore(o, a);
				};
				window.ttq = ttq;
				window.ttq.load(this.config.pixelId);
			}
			resolve();
		});
	}

	async trackPage({
		path,
		titleId,
	}: {
		path?: string;
		title?: string;
		titleId?: string;
		permanent_audiences?: string[];
	}) {
		if (!this.config.doNotTrack) {
			window.ttq.track('ViewContent', {
				content_id: titleId ?? path,
				content_type: 'product',
			});
		}
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {
		// TikTok disabled for now as it was already off before Audience Refresher
		// if (this.config.doNotTrack || permanent_audiences.length === 0) return;
		// window.ttq.track('ViewContent', { contents: permanent_audiences });
	}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {}
}
