import { WebLocale } from '@/enums/web-locale';
import { Region } from '@/interfaces/locale-details';

export type WebLocales = { [key in WebLocale]: string };

export const WEB_LOCALES: WebLocales = {
	[WebLocale.ad]: 'ca_AD',
	[WebLocale.ae]: 'ar_AE',
	[WebLocale.ag]: 'en_AG',
	[WebLocale.al]: 'sq_AL',
	[WebLocale.ao]: 'pt_AO',
	[WebLocale.ar]: 'es_AR',
	[WebLocale.at]: 'de_AT',
	[WebLocale.au]: 'en_AU',
	[WebLocale.az]: 'az_AZ',
	[WebLocale.ba]: 'bs_BA',
	[WebLocale.bb]: 'en_BB',
	[WebLocale.be]: 'fr_BE',
	[WebLocale.bf]: 'fr_BF',
	[WebLocale.bg]: 'bg_BG',
	[WebLocale.bh]: 'ar_BH',
	[WebLocale.bm]: 'en_BM',
	[WebLocale.bo]: 'es_BO',
	[WebLocale.br]: 'pt_BR',
	[WebLocale.bs]: 'en_BS',
	[WebLocale.by]: 'be_BY',
	[WebLocale.bz]: 'en_BZ',
	[WebLocale.ca]: 'en_CA',
	[WebLocale.cd]: 'fr_CD',
	[WebLocale.ch]: 'de_CH',
	[WebLocale.ci]: 'fr_CI',
	[WebLocale.cl]: 'es_CL',
	[WebLocale.cm]: 'en_CM',
	[WebLocale.co]: 'es_CO',
	[WebLocale.cr]: 'es_CR',
	[WebLocale.cu]: 'es_CU',
	[WebLocale.cv]: 'pt_CV',
	[WebLocale.cy]: 'el_CY',
	[WebLocale.cz]: 'cs_CZ',
	[WebLocale.de]: 'de_DE',
	[WebLocale.dk]: 'en_DK',
	[WebLocale.do]: 'es_DO',
	[WebLocale.dz]: 'ar_DZ',
	[WebLocale.ec]: 'es_EC',
	[WebLocale.ee]: 'et_EE',
	[WebLocale.eg]: 'ar_EG',
	[WebLocale.es]: 'es_ES',
	[WebLocale.fi]: 'fi_FI',
	[WebLocale.fj]: 'en_FJ',
	[WebLocale.fr]: 'fr_FR',
	[WebLocale.gf]: 'fr_GF',
	[WebLocale.gg]: 'en_GG',
	[WebLocale.gh]: 'en_GH',
	[WebLocale.gi]: 'en_GI',
	[WebLocale.gq]: 'es_GQ',
	[WebLocale.gr]: 'el_GR',
	[WebLocale.gt]: 'es_GT',
	[WebLocale.gy]: 'en_GY',
	[WebLocale.hk]: 'zh_HK',
	[WebLocale.hn]: 'es_HN',
	[WebLocale.hr]: 'hr_HR',
	[WebLocale.hu]: 'hu_HU',
	[WebLocale.id]: 'en_ID',
	[WebLocale.ie]: 'en_IE',
	[WebLocale.il]: 'he_IL',
	[WebLocale.in]: 'en_IN',
	[WebLocale.iq]: 'ar_IQ',
	[WebLocale.is]: 'is_IS',
	[WebLocale.it]: 'it_IT',
	[WebLocale.jm]: 'en_JM',
	[WebLocale.jo]: 'ar_JO',
	[WebLocale.jp]: 'ja_JP',
	[WebLocale.ke]: 'en_KE',
	[WebLocale.kr]: 'ko_KR',
	[WebLocale.kw]: 'ar_KW',
	[WebLocale.lb]: 'ar_LB',
	[WebLocale.lc]: 'en_LC',
	[WebLocale.li]: 'de_LI',
	[WebLocale.lt]: 'lt_LT',
	[WebLocale.lu]: 'fr_LU',
	[WebLocale.lv]: 'lv_LV',
	[WebLocale.ly]: 'ar_LY',
	[WebLocale.ma]: 'ar_MA',
	[WebLocale.mc]: 'fr_MC',
	[WebLocale.md]: 'ro_MD',
	[WebLocale.me]: 'sr_ME',
	[WebLocale.mg]: 'fr_MG',
	[WebLocale.mk]: 'mk_MK',
	[WebLocale.ml]: 'fr_ML',
	[WebLocale.mt]: 'mt_MT',
	[WebLocale.mu]: 'fr_MU',
	[WebLocale.mw]: 'en_MW',
	[WebLocale.mx]: 'es_MX',
	[WebLocale.my]: 'en_MY',
	[WebLocale.mz]: 'pt_MZ',
	[WebLocale.ne]: 'fr_NE',
	[WebLocale.ng]: 'en_NG',
	[WebLocale.ni]: 'es_NI',
	[WebLocale.nl]: 'en_NL',
	[WebLocale.no]: 'en_NO',
	[WebLocale.nz]: 'en_NZ',
	[WebLocale.om]: 'ar_OM',
	[WebLocale.pa]: 'es_PA',
	[WebLocale.pe]: 'es_PE',
	[WebLocale.pf]: 'fr_PF',
	[WebLocale.pg]: 'en_PG',
	[WebLocale.ph]: 'en_PH',
	[WebLocale.pk]: 'ur_PK',
	[WebLocale.pl]: 'pl_PL',
	[WebLocale.ps]: 'ar_PS',
	[WebLocale.pt]: 'pt_PT',
	[WebLocale.py]: 'es_PY',
	[WebLocale.qa]: 'ar_QA',
	[WebLocale.ro]: 'ro_RO',
	[WebLocale.rs]: 'sr_RS',
	[WebLocale.ru]: 'ru_RU',
	[WebLocale.sa]: 'ar_SA',
	[WebLocale.sc]: 'fr_SC',
	[WebLocale.se]: 'sv_SE',
	[WebLocale.sg]: 'en_SG',
	[WebLocale.si]: 'sl_SI',
	[WebLocale.sk]: 'sk_SK',
	[WebLocale.sm]: 'it_SM',
	[WebLocale.sn]: 'fr_SN',
	[WebLocale.sv]: 'es_SV',
	[WebLocale.tc]: 'en_TC',
	[WebLocale.td]: 'ar_TD',
	[WebLocale.th]: 'en_TH',
	[WebLocale.tn]: 'ar_TN',
	[WebLocale.tr]: 'tr_TR',
	[WebLocale.tt]: 'en_TT',
	[WebLocale.tw]: 'zh_TW',
	[WebLocale.tz]: 'sw_TZ',
	[WebLocale.ua]: 'uk_UA',
	[WebLocale.ug]: 'en_UG',
	[WebLocale.uk]: 'en_GB',
	[WebLocale.us]: 'en_US',
	[WebLocale.uy]: 'es_UY',
	[WebLocale.va]: 'it_VA',
	[WebLocale.ve]: 'es_VE',
	[WebLocale.xk]: 'sq_XK',
	[WebLocale.ye]: 'ar_YE',
	[WebLocale.za]: 'en_ZA',
	[WebLocale.zm]: 'en_ZM',
	[WebLocale.zw]: 'en_ZW',
};

export const WEB_LOCALES_BY_REGION: Record<Region, WebLocale[]> = {
	[Region.AFRICA]: [
		WebLocale.ao,
		WebLocale.bf,
		WebLocale.ca,
		WebLocale.ci,
		WebLocale.cm,
		WebLocale.cv,
		WebLocale.dz,
		WebLocale.gh,
		WebLocale.gq,
		WebLocale.ke,
		WebLocale.ly,
		WebLocale.ma,
		WebLocale.mg,
		WebLocale.ml,
		WebLocale.mu,
		WebLocale.mw,
		WebLocale.mz,
		WebLocale.ne,
		WebLocale.ng,
		WebLocale.sc,
		WebLocale.sn,
		WebLocale.td,
		WebLocale.tn,
		WebLocale.tz,
		WebLocale.ug,
		WebLocale.za,
		WebLocale.zm,
		WebLocale.zw,
	],

	[Region.ASIA]: [
		WebLocale.hk,
		WebLocale.id,
		WebLocale.in,
		WebLocale.jp,
		WebLocale.kr,
		WebLocale.lb,
		WebLocale.my,
		WebLocale.ph,
		WebLocale.pk,
		WebLocale.sg,
		WebLocale.th,
		WebLocale.tw,
	],

	[Region.CARIBBEAN]: [
		WebLocale.ag,
		WebLocale.bb,
		WebLocale.bs,
		WebLocale.cu,
		WebLocale.do,
		WebLocale.jm,
		WebLocale.lc,
		WebLocale.tc,
		WebLocale.tt,
	],

	[Region.CENTRAL_AMERICA]: [
		WebLocale.bz,
		WebLocale.cr,
		WebLocale.gt,
		WebLocale.hn,
		WebLocale.ni,
		WebLocale.pa,
		WebLocale.sv,
	],

	[Region.EUROPE]: [
		WebLocale.ad,
		WebLocale.al,
		WebLocale.az,
		WebLocale.at,
		WebLocale.ba,
		WebLocale.be,
		WebLocale.bg,
		WebLocale.by,
		WebLocale.ch,
		WebLocale.cy,
		WebLocale.cz,
		WebLocale.de,
		WebLocale.dk,
		WebLocale.ee,
		WebLocale.es,
		WebLocale.fi,
		WebLocale.fr,
		WebLocale.gg,
		WebLocale.gi,
		WebLocale.gr,
		WebLocale.hr,
		WebLocale.hu,
		WebLocale.ie,
		WebLocale.is,
		WebLocale.it,
		WebLocale.li,
		WebLocale.lt,
		WebLocale.lu,
		WebLocale.lv,
		WebLocale.mc,
		WebLocale.md,
		WebLocale.me,
		WebLocale.mk,
		WebLocale.mt,
		WebLocale.nl,
		WebLocale.no,
		WebLocale.pl,
		WebLocale.pt,
		WebLocale.ro,
		WebLocale.rs,
		WebLocale.ru,
		WebLocale.se,
		WebLocale.sk,
		WebLocale.sm,
		WebLocale.tr,
		WebLocale.ua,
		WebLocale.uk,
		WebLocale.va,
		WebLocale.xk,
		WebLocale.si,
	],

	[Region.MIDDLE_EAST]: [
		WebLocale.ae,
		WebLocale.bh,
		WebLocale.eg,
		WebLocale.il,
		WebLocale.iq,
		WebLocale.jo,
		WebLocale.kw,
		WebLocale.om,
		WebLocale.ps,
		WebLocale.qa,
		WebLocale.sa,
		WebLocale.ye,
	],
	[Region.NORTH_AMERICA]: [WebLocale.bm, WebLocale.ca, WebLocale.mx, WebLocale.us],

	[Region.PACIFIC]: [WebLocale.au, WebLocale.fj, WebLocale.nz, WebLocale.pf, WebLocale.pg],

	[Region.SOUTH_AMERICA]: [
		WebLocale.ar,
		WebLocale.bo,
		WebLocale.br,
		WebLocale.cl,
		WebLocale.co,
		WebLocale.ec,
		WebLocale.gf,
		WebLocale.gy,
		WebLocale.pe,
		WebLocale.py,
		WebLocale.uy,
		WebLocale.ve,
	],
};

export const WEB_LOCALES_EMOJIS: { [key: string]: string } = {
	[WebLocale.ad]: '🇦🇩',
	[WebLocale.ae]: '🇦🇪',
	[WebLocale.ag]: '🇦🇬',
	[WebLocale.al]: '🇦🇱',
	[WebLocale.az]: '🇦🇿',
	[WebLocale.ao]: '🇦🇴',
	[WebLocale.ar]: '🇦🇷',
	[WebLocale.at]: '🇦🇹',
	[WebLocale.au]: '🇦🇺',
	[WebLocale.ba]: '🇧🇦',
	[WebLocale.bb]: '🇧🇧',
	[WebLocale.be]: '🇧🇪',
	[WebLocale.bf]: '🇧🇫',
	[WebLocale.bg]: '🇧🇬',
	[WebLocale.bh]: '🇧🇭',
	[WebLocale.bm]: '🇧🇲',
	[WebLocale.bo]: '🇧🇴',
	[WebLocale.br]: '🇧🇷',
	[WebLocale.bs]: '🇧🇸',
	[WebLocale.by]: '🇧🇾',
	[WebLocale.bz]: '🇧🇿',
	[WebLocale.ca]: '🇨🇦',
	[WebLocale.cd]: '🇨🇩',
	[WebLocale.ch]: '🇨🇭',
	[WebLocale.ci]: '🇨🇮',
	[WebLocale.cl]: '🇨🇱',
	[WebLocale.cm]: '🇨🇲',
	[WebLocale.co]: '🇨🇴',
	[WebLocale.cr]: '🇨🇷',
	[WebLocale.cu]: '🇨🇺',
	[WebLocale.cv]: '🇨🇻',
	[WebLocale.cy]: '🇨🇾',
	[WebLocale.cz]: '🇨🇿',
	[WebLocale.de]: '🇩🇪',
	[WebLocale.dk]: '🇩🇰',
	[WebLocale.do]: '🇩🇴',
	[WebLocale.dz]: '🇩🇿',
	[WebLocale.ec]: '🇪🇨',
	[WebLocale.ee]: '🇪🇪',
	[WebLocale.eg]: '🇪🇬',
	[WebLocale.es]: '🇪🇸',
	[WebLocale.fi]: '🇫🇮',
	[WebLocale.fj]: '🇫🇯',
	[WebLocale.fr]: '🇫🇷',
	[WebLocale.gf]: '🇬🇫',
	[WebLocale.gg]: '🇬🇬',
	[WebLocale.gh]: '🇬🇭',
	[WebLocale.gi]: '🇬🇮',
	[WebLocale.gq]: '🇬🇶',
	[WebLocale.gr]: '🇬🇷',
	[WebLocale.gt]: '🇬🇹',
	[WebLocale.gy]: '🇬🇾',
	[WebLocale.hk]: '🇭🇰',
	[WebLocale.hn]: '🇭🇳',
	[WebLocale.hr]: '🇭🇷',
	[WebLocale.hu]: '🇭🇺',
	[WebLocale.id]: '🇮🇩',
	[WebLocale.ie]: '🇮🇪',
	[WebLocale.il]: '🇮🇱',
	[WebLocale.in]: '🇮🇳',
	[WebLocale.iq]: '🇮🇶',
	[WebLocale.is]: '🇮🇸',
	[WebLocale.it]: '🇮🇹',
	[WebLocale.jm]: '🇯🇲',
	[WebLocale.jo]: '🇯🇴',
	[WebLocale.jp]: '🇯🇵',
	[WebLocale.ke]: '🇰🇪',
	[WebLocale.kr]: '🇰🇷',
	[WebLocale.kw]: '🇰🇼',
	[WebLocale.lb]: '🇱🇧',
	[WebLocale.lc]: '🇱🇨',
	[WebLocale.li]: '🇱🇮',
	[WebLocale.lt]: '🇱🇹',
	[WebLocale.lu]: '🇱🇺',
	[WebLocale.lv]: '🇱🇻',
	[WebLocale.ly]: '🇱🇾',
	[WebLocale.ma]: '🇲🇦',
	[WebLocale.mc]: '🇲🇨',
	[WebLocale.md]: '🇲🇩',
	[WebLocale.me]: '🇲🇪',
	[WebLocale.mg]: '🇲🇬',
	[WebLocale.mk]: '🇲🇰',
	[WebLocale.ml]: '🇲🇱',
	[WebLocale.mt]: '🇲🇹',
	[WebLocale.mu]: '🇲🇺',
	[WebLocale.mw]: '🇲🇼',
	[WebLocale.mx]: '🇲🇽',
	[WebLocale.my]: '🇲🇾',
	[WebLocale.mz]: '🇲🇿',
	[WebLocale.ne]: '🇳🇪',
	[WebLocale.ng]: '🇳🇬',
	[WebLocale.ni]: '🇳🇮',
	[WebLocale.nl]: '🇳🇱',
	[WebLocale.no]: '🇳🇴',
	[WebLocale.nz]: '🇳🇿',
	[WebLocale.om]: '🇴🇲',
	[WebLocale.pa]: '🇵🇦',
	[WebLocale.pe]: '🇵🇪',
	[WebLocale.pf]: '🇵🇫',
	[WebLocale.pg]: '🇵🇬',
	[WebLocale.ph]: '🇵🇭',
	[WebLocale.pk]: '🇵🇰',
	[WebLocale.pl]: '🇵🇱',
	[WebLocale.ps]: '🇵🇸',
	[WebLocale.pt]: '🇵🇹',
	[WebLocale.py]: '🇵🇾',
	[WebLocale.qa]: '🇶🇦',
	[WebLocale.ro]: '🇷🇴',
	[WebLocale.rs]: '🇷🇸',
	[WebLocale.ru]: '🇷🇺',
	[WebLocale.sa]: '🇸🇦',
	[WebLocale.sc]: '🇸🇨',
	[WebLocale.se]: '🇸🇪',
	[WebLocale.sg]: '🇸🇬',
	[WebLocale.sk]: '🇸🇰',
	[WebLocale.si]: '🇸🇮',
	[WebLocale.sm]: '🇸🇲',
	[WebLocale.sn]: '🇸🇳',
	[WebLocale.sv]: '🇸🇻',
	[WebLocale.tc]: '🇹🇨',
	[WebLocale.td]: '🇹🇩',
	[WebLocale.th]: '🇹🇭',
	[WebLocale.tn]: '🇹🇳',
	[WebLocale.tr]: '🇹🇷',
	[WebLocale.tt]: '🇹🇹',
	[WebLocale.tw]: '🇹🇼',
	[WebLocale.tz]: '🇹🇿',
	[WebLocale.ua]: '🇺🇦',
	[WebLocale.ug]: '🇺🇬',
	[WebLocale.uk]: '🇬🇧',
	[WebLocale.us]: '🇺🇸',
	[WebLocale.uy]: '🇺🇾',
	[WebLocale.va]: '🇻🇦',
	[WebLocale.ve]: '🇻🇪',
	[WebLocale.xk]: '🇽🇰',
	[WebLocale.ye]: '🇾🇪',
	[WebLocale.za]: '🇿🇦',
	[WebLocale.zm]: '🇿🇲',
	[WebLocale.zw]: '🇿🇼',
};

export const EU_WEB_LOCALES: WebLocale[] = [
	WebLocale.at,
	WebLocale.be,
	WebLocale.bg,
	WebLocale.cy,
	WebLocale.cz,
	WebLocale.de,
	WebLocale.dk,
	WebLocale.ee,
	WebLocale.es,
	WebLocale.fi,
	WebLocale.fr,
	WebLocale.gr,
	WebLocale.hr,
	WebLocale.hu,
	WebLocale.ie,
	WebLocale.it,
	WebLocale.lt,
	WebLocale.lu,
	WebLocale.lv,
	WebLocale.mt,
	WebLocale.nl,
	WebLocale.pl,
	WebLocale.pt,
	WebLocale.ro,
	WebLocale.se,
	WebLocale.sk,
	WebLocale.si,
	WebLocale.uk,
];

export const COUNTRY_EXCEPTIONS: Record<string, WebLocale> = {
	gb: WebLocale.uk,
};

// https://justwatch.slack.com/archives/C0DNNH6GK/p1620305502073600?thread_ts=1620302605.073500&cid=C0DNNH6GK
export const LANG_COUNTRY_MAPPING: Record<string, WebLocale> = {
	ar: WebLocale.eg,
	az: WebLocale.az,
	be: WebLocale.by,
	bg: WebLocale.bg,
	bs: WebLocale.ba,
	ca: WebLocale.es,
	cs: WebLocale.cz,
	de: WebLocale.de,
	el: WebLocale.gr,
	en: WebLocale.uk,
	['en-US']: WebLocale.us,
	es: WebLocale.gq,
	['es-ES']: WebLocale.es,
	['es-419']: WebLocale.mx,
	et: WebLocale.ee,
	fi: WebLocale.fi,
	fr: WebLocale.fr,
	['fr-CA']: WebLocale.ca,
	he: WebLocale.il,
	hr: WebLocale.hr,
	hu: WebLocale.hu,
	is: WebLocale.is,
	it: WebLocale.it,
	ja: WebLocale.jp,
	ko: WebLocale.kr,
	lt: WebLocale.lt,
	lv: WebLocale.lv,
	mk: WebLocale.mk,
	mt: WebLocale.mt,
	pl: WebLocale.pl,
	pt: WebLocale.pt,
	['pt-PT']: WebLocale.pt,
	['pt-BR']: WebLocale.br,
	ro: WebLocale.ro,
	ru: WebLocale.ru,
	sk: WebLocale.sk,
	sl: WebLocale.si,
	sq: WebLocale.al,
	sr: WebLocale.rs,
	sv: WebLocale.se,
	sw: WebLocale.tz,
	tr: WebLocale.tr,
	uk: WebLocale.ua,
	ur: WebLocale.pk,
	zh: WebLocale.tw,
};

export const COUNTRY_EXCLUDED: Record<string, WebLocale> = {};

export const COUNTRY_POPULAR_PAGES_WITH_NOINDEX = [
	WebLocale.ad,
	WebLocale.ae,
	WebLocale.ag,
	WebLocale.al,
	WebLocale.ao,
	WebLocale.az,
	WebLocale.ba,
	WebLocale.bb,
	WebLocale.bf,
	WebLocale.bh,
	WebLocale.bm,
	WebLocale.bo,
	WebLocale.bs,
	WebLocale.by,
	WebLocale.bz,
	WebLocale.cd,
	WebLocale.ci,
	WebLocale.cm,
	WebLocale.cr,
	WebLocale.cu,
	WebLocale.cv,
	WebLocale.cy,
	WebLocale.do,
	WebLocale.dz,
	WebLocale.fj,
	WebLocale.gf,
	WebLocale.gg,
	WebLocale.gh,
	WebLocale.gi,
	WebLocale.gq,
	WebLocale.gr,
	WebLocale.gt,
	WebLocale.gy,
	WebLocale.hn,
	WebLocale.il,
	WebLocale.jm,
	WebLocale.jo,
	WebLocale.ke,
	WebLocale.kw,
	WebLocale.lc,
	WebLocale.li,
	WebLocale.lu,
	WebLocale.ly,
	WebLocale.ma,
	WebLocale.mc,
	WebLocale.md,
	WebLocale.me,
	WebLocale.mg,
	WebLocale.mk,
	WebLocale.ml,
	WebLocale.mt,
	WebLocale.mu,
	WebLocale.mw,
	WebLocale.mz,
	WebLocale.ne,
	WebLocale.ng,
	WebLocale.ni,
	WebLocale.om,
	WebLocale.pa,
	WebLocale.pf,
	WebLocale.pg,
	WebLocale.pk,
	WebLocale.py,
	WebLocale.qa,
	WebLocale.rs,
	WebLocale.sa,
	WebLocale.sc,
	WebLocale.si,
	WebLocale.sm,
	WebLocale.sn,
	WebLocale.sv,
	WebLocale.tc,
	WebLocale.td,
	WebLocale.tn,
	WebLocale.tt,
	WebLocale.tz,
	WebLocale.ua,
	WebLocale.ug,
	WebLocale.uy,
	WebLocale.ve,
	WebLocale.ye,
	WebLocale.zm,
	WebLocale.zw,
	WebLocale.lv,
	WebLocale.th,
];

export const COUNTRY_WITHOUT_TITLEDETAIL = [
	WebLocale.td,
	WebLocale.bf,
	WebLocale.gq,
	WebLocale.tc,
	WebLocale.sc,
	WebLocale.ml,
	WebLocale.cu,
	WebLocale.lc,
	WebLocale.gi,
	WebLocale.li,
	WebLocale.cd,
	WebLocale.pg,
	WebLocale.mw,
	WebLocale.mc,
	WebLocale.sm,
	WebLocale.va,
	WebLocale.cm,
	WebLocale.mg,
	WebLocale.xk,
	WebLocale.me,
	WebLocale.cv,
	WebLocale.fj,
	WebLocale.gy,
	WebLocale.pf,
	WebLocale.ne,
	WebLocale.zw,
	WebLocale.ye,
	WebLocale.tz,
	WebLocale.gg,
	WebLocale.md,
	WebLocale.zm,
	WebLocale.ug,
	WebLocale.ci,
	WebLocale.ly,
	WebLocale.bz,
	WebLocale.sn,
	WebLocale.bb,
	WebLocale.mt,
	WebLocale.cy,
	WebLocale.by,
	WebLocale.om,
	WebLocale.mz,
	WebLocale.gh,
	WebLocale.bs,
	WebLocale.tn,
	WebLocale.lb,
	WebLocale.ps,
	WebLocale.mk,
	WebLocale.tt,
	WebLocale.jo,
	WebLocale.lu,
	WebLocale.ba,
	WebLocale.mu,
	WebLocale.lv,
	WebLocale.bh,
	WebLocale.kw,
	WebLocale.gf,
	WebLocale.qa,
	WebLocale.bm,
	WebLocale.az,
	WebLocale.jm,
	WebLocale.ni,
	WebLocale.ke,
	WebLocale.ua,
	WebLocale.lt,
	WebLocale.ee,
	WebLocale.ma,
	WebLocale.is,
	WebLocale.ad,
	WebLocale.ag,
	WebLocale.py,
	WebLocale.ao,
	WebLocale.si,
	WebLocale.rs,
	WebLocale.do,
	WebLocale.hn,
	WebLocale.pa,
	WebLocale.sv,
	WebLocale.il,
	WebLocale.uy,
	WebLocale.dz,
	WebLocale.ng,
	WebLocale.iq,
	WebLocale.al,
	WebLocale.hk,
];

export const COOKIE_BANNER_WEB_LOCALES: WebLocale[] = [WebLocale.in];

export const GDPR_WEB_LOCALES: WebLocale[] = [
	WebLocale.al,
	WebLocale.ar,
	WebLocale.at,
	WebLocale.be,
	WebLocale.bg,
	WebLocale.ca,
	WebLocale.ch,
	WebLocale.cy,
	WebLocale.cz,
	WebLocale.de,
	WebLocale.dk,
	WebLocale.ee,
	WebLocale.es,
	WebLocale.fi,
	WebLocale.fr,
	WebLocale.gr,
	WebLocale.hr,
	WebLocale.hu,
	WebLocale.ie,
	WebLocale.is,
	WebLocale.it,
	WebLocale.jp,
	WebLocale.kr,
	WebLocale.lt,
	WebLocale.lu,
	WebLocale.lv,
	WebLocale.mt,
	WebLocale.nl,
	WebLocale.no,
	WebLocale.nz,
	WebLocale.pl,
	WebLocale.pt,
	WebLocale.ro,
	WebLocale.rs,
	WebLocale.se,
	WebLocale.sk,
	WebLocale.uk,
	WebLocale.uy,
	WebLocale.xk,
];

// Hard code the values for now, we can improve on this later
export const CCPA_SUBDIVISION: string[] = ['USCA'];
