
import { sanitize } from 'dompurify';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';

const SearchSuggesterResults = () =>
	import(
		/* webpackChunkName: "search-suggester-results" */ '@/components/search/SearchSuggesterResults/SearchSuggesterResults.vue'
	);

import type SearchResultItem from '@/components/search/SearchSuggesterResults/SearchResults/SearchResultItem.vue';
import type SearchPoster from '@/components/search/SearchSuggesterResults/SearchSuggesterEmpty/SearchPoster.vue';
import type SearchTerm from '@/components/search/SearchSuggesterResults/SearchSuggesterEmpty/SearchTerm.vue';

import { CollectionType } from '@/enums/collection-type';
import { Viewport } from '@/enums/viewport';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { getViewport } from '@/helpers/ui-helper';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const Language = namespace('language');
const Routing = namespace('routing');
const SearchSuggester = namespace('searchSuggester');

/**
 * @deprecated Should be replaced with new search.
 */
@Component({
	name: 'SearchSuggesterBar',
	components: {
		SearchSuggesterResults,
	},
})
export default class SearchSuggesterBar extends Vue {
	isMounted = false;
	items: Vue[] = [];
	activeElement: SearchTerm | SearchPoster | SearchResultItem;
	index = -1;
	previndex = -1;

	inputQuery = '';

	@Prop() collectionType: CollectionType;

	@Ref('search-result') readonly searchResult?: Vue;

	@Language.Getter language: string;
	@Language.Getter country: string;

	@Routing.State activeRoute: any;

	@SearchSuggester.Getter isSearchSuggesterOpen: boolean;
	@SearchSuggester.Getter query: string;
	@SearchSuggester.Getter currentView: string;
	@SearchSuggester.Action search: ({ searchQuery }: { searchQuery: string }) => void;
	@SearchSuggester.Action reset: () => void;
	@SearchSuggester.Action goToSearch: ({
		searchString,
		personId,
	}: {
		searchString: string;
		personId?: number;
		source?: string;
	}) => void;
	@SearchSuggester.Action setSearchSuggesterVisibility: (value: boolean) => void;

	get debounceValue() {
		return 300;
	}

	get viewport(): string {
		return getViewport();
	}

	get placeholder() {
		return this.viewport === Viewport.DESKTOP ? this.$t('WEBAPP_SEARCH_PLACEHOLDER') : this.$t('WEBAPP_SEARCH');
	}

	get showCancelButtonMobileWebApp() {
		return this.viewport === Viewport.SMARTPHONE && this.inputQuery?.length > 0 && this.isSearchSuggesterOpen;
	}

	get showCancelButton() {
		const forMobile = this.collectionType === CollectionType.SEARCH ? 'never' : 'always';
		return this.showCancelButtonMobileWebApp ? forMobile : 'never';
	}

	@Watch('collectionType')
	onCollectionTypeChange() {
		if (this.collectionType !== CollectionType.SEARCH) {
			this.$emit('ionCancel');
		}
	}

	@Watch('currentView')
	onViewChange() {
		this.resetSuggesterGridItems();
	}

	@Watch('query')
	onQueryChange() {
		if (!this.isSearchSuggesterOpen && !this.inputQuery) {
			this.inputQuery = this.query;
		}
	}

	/**
	 * close results when the route has been changed.
	 */
	@Watch('activeRoute')
	onRouteChange(route: Route) {
		this.closeSearchBar();

		// if the route has changed to a persons page, replace the input with the proper name of the person
		if (route.query.person_id) {
			(this.$refs.searchbar as HTMLIonSearchbarElement).value = route.query.q as string;
		}
	}

	mounted() {
		this.isMounted = true;
	}

	openSearchBar(evt: any) {
		if (!this.isSearchSuggesterOpen) {
			const userInput = sanitize(evt.target.value);

			this.search({ searchQuery: userInput });
			this.setSearchSuggesterVisibility(true);

			// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
			trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'open_suggester' });
			// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		}
	}

	closeSearchBar(click = false) {
		// if user is on search, we want to keep the search bar
		if (this.collectionType !== CollectionType.SEARCH) {
			this.$emit('ionCancel');
			this.setSearchSuggesterVisibility(false);
			if (click) {
				// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
				trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'close_suggester' });
				// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
			}
		}
	}

	onChange(evt: any) {
		const userInput = sanitize(evt.target.value);
		this.inputQuery = userInput;
		this.search({ searchQuery: this.inputQuery });
	}

	editSearch(searchTerm?: string) {
		const input = this.$refs.searchbar as HTMLIonSearchbarElement;
		input.setFocus().then(() => (input.value = searchTerm));
	}

	navigateElements(evt: KeyboardEvent) {
		const { code, target } = evt;
		this.items = this.loadSuggesterGridItems();
		if (this.activeElement === null && this.items.length === 0) {
			return;
		} else {
			const userInput = sanitize((target as HTMLInputElement).value);
			switch (code) {
				case 'ArrowDown':
				case 'ArrowRight':
					{
						if (this.items.length > 0 && this.index + 1 <= this.items.length - 1) {
							this.previndex = this.index;
							this.index++;
						} else {
							this.previndex = this.index;
							this.index = 0;
						}

						this.move();
					}

					break;
				case 'ArrowUp':
				case 'ArrowLeft':
					{
						if (this.index - 1 >= 0) {
							this.previndex = this.index;
							this.index--;
						} else {
							this.previndex = this.index;
							this.index = this.items.length - 1;
						}

						this.move();
					}

					break;
				case 'Escape': //escape
					this.clear('escape');
					this.resetSuggesterGridItems();

					if (!userInput.length) {
						this.closeSearchBar();
					}
					break;
				case 'Enter': //enter
					if (this.activeElement && this.activeElement.focused) {
						this.activeElement.clicked();
					} else if (this.index === -1 && this.previndex === -1) {
						if (userInput.length >= 2) {
							this.resetSuggesterGridItems();
							this.activeElement?.unfocus();
							this.navigateToSearchPage(userInput);
						}
					}
					break;
				case 'Tab':
				case 'Backspace':
				case 'ControlLeft':
				case 'ControlRight':
				case 'AltLeft':
				case 'AltRight':
				case 'ShiftLeft':
				case 'ShiftRight':
				case 'OSLeft':
				case 'OSRight':
				case 'Space':
				case 'CapsLock':
				case 'Fn':
					break;
				default:
					this.setSearchSuggesterVisibility(true);
					return;
			}
		}
	}

	clear(source: string) {
		TrackingHelper.trackEvent('search_suggest_clear', {
			action: source,
		});

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'search_suggest_clear' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}

	/** GRID NAVIGATION METHODS */
	private move() {
		if (this.previndex >= 0) {
			this.unfocusElement(this.previndex);
		}

		this.focusElement(this.index);
		this.activeElement = this.getElement(this.items[this.index]);
	}

	private focusElement(index: number) {
		const activeElement = this.getElement(this.items[index]);
		activeElement?.focus();
	}

	private unfocusElement(index: number) {
		const activeElement = this.getElement(this.items[index]);
		if (activeElement?.focused) {
			activeElement.unfocus();
		}
	}

	private loadSuggesterGridItems(): Vue[] {
		const { searchResult } = this;
		return searchResult?.$children[0]?.$children || [];
	}

	private resetSuggesterGridItems() {
		this.index = -1;
		this.previndex = -1;
	}

	private getElement(element: Vue): SearchTerm | SearchPoster | SearchResultItem {
		const elementName: string | undefined =
			element.$vnode.componentOptions != undefined ? element.$vnode.componentOptions.tag : '';
		switch (elementName) {
			case 'SearchTerm':
				return element as SearchTerm;
			case 'SearchPoster':
				return element as SearchPoster;
			case 'SearchResultItem':
				return element as SearchResultItem;
			default:
				return element as SearchTerm;
		}
	}

	private navigateToSearchPage(searchQuery: string) {
		this.setSearchSuggesterVisibility(false);
		this.goToSearch({ searchString: searchQuery });
		TrackingHelper.trackEvent('search_suggest_more', {
			action: 'enter',
			label: searchQuery,
		});

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'search_suggest_more' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}
}
