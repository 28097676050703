import { DataProcessingService } from '@/helpers/usercentrics-helper';
import { SnowplowContext } from './snowplow-contexts';
import { useUserAgent } from '@/helpers/composables/useUserAgent';
import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';
import { paToParentIDMap } from '@/helpers/tracking/util/paParentMapping';

declare global {
	interface Window {
		dataLayer: any[];
	}
}

export class TrackingProviderGoogleTagManager extends TrackingProvider {
	dataProcessingService = DataProcessingService.GOOGLE_TAG_MANAGER;

	constructor(private config: { containerId: string; doNotTrack: boolean }) {
		super();
	}

	initialize() {
		return new Promise<void>(resolve => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			const f = document.getElementsByTagName('script')[0],
				j = document.createElement('script'),
				dl = 'dataLayer' != 'dataLayer' ? '&l=' + 'dataLayer' : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.config.containerId + dl;
			f.parentNode!.insertBefore(j, f);
			resolve();
		});
	}

	async trackPage({ path }: { path?: string; title?: string }) {
		// if (!this.config.doNotTrack) {
		const dataLayer = (window.dataLayer = window.dataLayer || []);
		dataLayer.push({
			event: 'content-view',
			'content-name': path,
			userId: undefined,
		});
		// }
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {
		if (this.config.doNotTrack || permanent_audiences.length === 0) return;

		// some of the PAs have a `pa:` prefix
		const pa_ids = permanent_audiences.map(pa => pa.split('pa:').at?.(-1) ?? '');

		const parent_list_id = pa_ids
			.filter(pa => pa in paToParentIDMap)
			.flatMap(pa => paToParentIDMap[pa])
			.map(({ listId }) => listId);

		const dataLayer = (window.dataLayer = window.dataLayer || []);
		dataLayer.push({
			event: 'permanent_audiences',
			u9: pa_ids.join(';'),
			t8: parent_list_id.join(';'),
			send_to: 'DC-5281021/s_init/justp00+standard',
		});
	}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {
		const { deviceInfo } = useUserAgent();

		// if (!this.config.doNotTrack) {
		const dataLayer = (window.dataLayer = window.dataLayer || []);
		properties = properties || {};
		dataLayer.push({
			event: 'interaction',
			target: category,
			action: properties.action,
			'target-properties': properties.label,
			value: properties.value,
			'interaction-type': properties.nonInteraction,
			userId: undefined,
			device: deviceInfo.value,
		});
		// }
	}
}
