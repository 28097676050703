import {
	ProviderType,
	ProviderTypeToMonetizationTypes,
	ProviderTypeWithMonetizationTypes,
} from '@/components/filter-bar/filter-bar.types';
import { Subgenre, type Genre } from '@/constants/types';
import { GetConstantsDocument } from '@/graphql/queries/GetConstants.query';
import { pluck } from '@/helpers/object-helper';
import { headerfulCachedRequest } from '@/helpers/request-helper';
import Provider from '@/interfaces/provider';
import { GetSubgenresQuery, GetSubgenresQueryVariables } from '@/pages/graphql/queries/GetSubgenres.query';
import { FreezingItems } from '@/store';
import Vue from 'vue';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ReadyType } from './ready.store';

interface ConstantsResponse {
	genres: Genre[];
	movies: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
	organizations: string[];
	shows: Array<{ __typename?: 'AgeCertification'; technicalName: string; id: number }>;
}

export type ConstantState = {
	providers: Provider[];
	upcomingProviders: Set<number>;
	subgenresList: Subgenre[];
	genres: Genre[];
	ageCertifications: Pick<ConstantsResponse, 'movies' | 'shows' | 'organizations'> | { hasCertifications: boolean };
};

type ConstantGetter<T> = (state: ConstantState) => T;

export type ConstantGetters = {
	providers: ConstantGetter<Provider[]>;
	providersById: ConstantGetter<Record<string, Provider>>;
	providersByShortName: ConstantGetter<Record<string, Provider>>;
	providersByType: ConstantGetter<Record<ProviderType, Provider[]>>;
	genresByShortName: ConstantGetter<Record<string, Genre>>;
};

export type ConstantStore = {
	state: ConstantState;
	getters: ConstantGetters;
};

export enum ConstantType {
	PROVIDERS = 'providers',
}

export const CONSTANT_INIT_FREEZE_ITEMS: FreezingItems<ConstantState> = {
	providers: state => {
		(['providers'] as (keyof ConstantState)[]).map(key => state[key]).forEach((item: any) => Object.freeze(item));
	},
};

const state = () =>
	({
		providers: [],
		upcomingProviders: new Set(),
		subgenresList: [],
		genres: [],
		ageCertifications: {
			hasCertifications: false,
			movies: [],
			shows: [],
			organizations: [],
		},
	} as ConstantState);

// GETTERS
const getters: GetterTree<ConstantState, any> = {
	/**
	 * visible providers for the provider filter bar.
	 */

	allProviders:
		(state, getters, rootState, rootGetters) =>
		(filterByUserProviders = true) => {
			let filteredProviders = state.providers || [];
			let { providers: userProviders = [] } = rootState.user.settings;
			// cheap trick for the wrong serialization userProviders on the server is an object instad of an array
			// loggedInProviders is also affected
			if (!(userProviders instanceof Array)) {
				userProviders = Object.values(userProviders);
			}

			if (filterByUserProviders && userProviders.length) {
				filteredProviders = filteredProviders.filter(provider => userProviders.includes(provider.shortName));
			}
			return filteredProviders;
		},
	allProvidersById: (state, getters) => {
		return pluck(getters.allProviders(false), 'packageId');
	},
	providers:
		(state, getters, rootState, rootGetters) =>
		(filterByUserProviders = true) => {
			if (!rootState.routing.activeRoute?.name?.startsWith('app.sports')) {
				return getters.allProviders(filterByUserProviders).filter((p: Provider) => p.hasTitles);
			}

			return getters.sportProviders;
		},
	sportProviders(state): Provider[] {
		return state.providers.filter(p => p.hasSport);
	},
	titleProviders(state): Provider[] {
		return state.providers.filter(p => p.hasTitles);
	},
	titleProviderCount(state, getters): number {
		return getters.titleProviders.length;
	},
	sportProvidersById(state, getters): Record<number, Provider> {
		return pluck(getters.sportProviders, 'packageId');
	},
	sportProvidersByShortName(state, getters): Record<string, Provider> {
		return pluck(getters.sportProviders, 'shortName');
	},

	titleProvidersById(state, getters): Record<number, Provider> {
		return pluck(getters.titleProviders, 'packageId');
	},
	titleProvidersByShortName(state, getters): Record<string, Provider> {
		return pluck(getters.titleProviders, 'shortName');
	},
	providersById(state, getters, rootState): Record<number, Provider> {
		if (!rootState.routing.activeRoute?.name?.startsWith('app.sports')) {
			return getters.titleProvidersById;
		}
		return getters.sportProvidersById;
	},
	providersByShortName(state, getters, rootState): Record<string, Provider> {
		if (!rootState.routing.activeRoute?.name?.startsWith('app.sports')) {
			return getters.titleProvidersByShortName;
		}
		return getters.sportProvidersByShortName;
	},
	providersByType(state, getters) {
		return getters
			.providers(false)
			.reduce((providersByType: Record<ProviderType, Provider[]>, provider: Provider) => {
				const providerType = Object.keys(ProviderTypeToMonetizationTypes).find(providerType =>
					provider.monetizationTypes?.find(mt =>
						ProviderTypeToMonetizationTypes[providerType as ProviderTypeWithMonetizationTypes].includes(mt)
					)
				) as ProviderType;

				if (!providerType) {
					return providersByType;
				}

				return {
					...providersByType,
					[providerType]: [...(providersByType[providerType] || []), provider],
				};
			}, {} as Record<ProviderType, Provider[]>);
	},
	genresByShortName: state => pluck(state.genres, 'shortName'),
	/**
	 * get first three providers of the region to preselect for a new user
	 */
	preselectedProviders: (state, getters, rootState): string[] => {
		const { providers: userProviders = [] } = rootState.user.settings;
		return userProviders.length > 0
			? userProviders
			: (getters.providers(false) as Provider[]).slice(0, 3).map(provider => provider.shortName);
	},
	subgenresList: state => state.subgenresList,
};

// ACTIONS
const actions: ActionTree<ConstantState, any> = {
	async fetchPackages({ commit, rootGetters, rootState }) {
		const query = `query GetPackages($country: Country!, $platform: Platform! = WEB) {
			packages(country: $country, platform: $platform, includeAddons: true) {
				clearName
				hasSport(country: $country, platform: $platform)
				hasTitles(country: $country, platform: $platform)
				icon
				monetizationTypes
				packageId
				selected
				shortName
				slug
				technicalName
			}
		}`;

		const variables = {
			country: rootGetters['language/country'],
		};
		const jwSession = await rootState.app.jwSession;
		const jwId = rootState.user.jwId;
		const response = await headerfulCachedRequest(query, variables, jwSession, jwId);
		const { packages = [] } = response?.data;
		commit('SET_PACKAGES', packages);
	},
	async fetchSubgenres({ commit, rootGetters, rootState }) {
		//objectType = movie | show returns same data, only order is changed
		const query = `query GetSubgenres($country: Country!, $language: Language!) {
			subgenres(objectType: "movie", country: $country) {
				content(country: $country, language: $language) {
					name
					fullPath
					shortName
					moviesUrl {
						fullPath
					}
					showsUrl {
						fullPath
					}
				}
			}
		}
		`;

		const variables: GetSubgenresQueryVariables = {
			country: rootGetters['language/country'],
			language: rootGetters['language/language'],
		};
		const jwSession = await rootState.app.jwSession;
		const jwId = rootState.user.jwId;
		const response = await headerfulCachedRequest(query, variables, jwSession, jwId);
		const { subgenres = [] } = response?.data;
		const subs: Subgenre[] = (subgenres as GetSubgenresQuery['subgenres']).map(({ content }) => {
			if (content.moviesUrl) {
				(content as Subgenre).slug = content.moviesUrl.fullPath.split('/').at(-2);
			} else if (content.showsUrl) {
				(content as Subgenre).slug = content.showsUrl.fullPath.split('/').at(-2);
			}
			return content;
		});
		commit('SET_SUBGENRES_LIST', subs);
	},
	async fetchConstants({ commit, rootGetters, rootState }) {
		const variables: GetSubgenresQueryVariables = {
			country: rootGetters['language/country'],
			language: rootGetters['language/language'],
		};
		const jwSession = await rootState.app.jwSession;
		const jwId = rootState.user.jwId;
		const response = await headerfulCachedRequest(GetConstantsDocument, variables, jwSession, jwId);
		const { genres, movies, organizations, shows } = response?.data as ConstantsResponse;
		commit('SET_CONSTANTS', {
			genres,
			movies,
			organizations,
			shows,
		});
		Vue.$jw.ready?.setReady(ReadyType.CONSTANTS);
	},
};

// MUTATIONS
const mutations: MutationTree<ConstantState> = {
	SET_PACKAGES(state, providers: Provider[]) {
		const items = [...providers].map(item => Object.freeze(item));
		state.providers = items;
	},
	SET_UPCOMING_PROVIDERS(state, providerIds: Set<number>) {
		state.upcomingProviders = providerIds;
	},
	SET_SUBGENRES_LIST: (state, subgenresFromApi: Subgenre[]) => {
		state.subgenresList = subgenresFromApi;
	},
	SET_CONSTANTS: (state, constantsFromApi: ConstantsResponse) => {
		state.genres = constantsFromApi.genres;
		state.ageCertifications = {
			hasCertifications: constantsFromApi.movies.length > 0 || constantsFromApi.shows.length > 0,
			movies: constantsFromApi.movies,
			shows: constantsFromApi.shows,
			organizations: [
				...new Set(
					constantsFromApi.organizations
						.map((item: any) => item.organization)
						.filter((name: string) => name !== '')
				),
			],
		};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<ConstantState, any>;
