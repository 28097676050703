import { ROLE } from '@/enums/person';

import type {
	Movie,
	MovieContent,
	MovieOrShowContent,
	NewTitlesEdge,
	Season,
	ShowContent,
} from '@/@types/graphql-types';
import { CreditRole, ObjectType } from '@/@types/graphql-types';
import type { TitleDetail } from '@/interfaces/title-details-graphql';
import { ArticleFragment } from '@/pages/graphql/queries/GetArticleByUrl.query';
import { AuthorDataFragment } from '@/pages/graphql/queries/GetAuthorByUrl.query';

import { Genre } from '@/constants/types';
import { createThumborFilter } from '@/filters/thumbor';
import { ClickoutUrl } from '@/helpers/clickout-helper';
import { getArticleBackdrop } from '@/helpers/editorial-helper';
import { AssertByTypename } from '@/helpers/graphql-model-helper';
import { getScorings } from '@/helpers/scoring-helper';
import { getVm } from '@/helpers/vm-helper';
import { Country } from '@/interfaces/locale-details';
import { GetPopularTitlesQuery } from '@/pages/graphql/queries/GetPopularTitles.query';

const PROTOCOL = 'https://';
const SUBDOMAIN = (JW_CONFIG.DOMAIN as string)?.startsWith('appdev') ? '' : 'www.';
const APP_URL = `${PROTOCOL}${SUBDOMAIN}${JW_CONFIG.DOMAIN}`;

export function getJsonLdContent(
	title: TitleDetail,
	genresByShortName: Record<string, Genre>,
	thumborFilter: ReturnType<typeof createThumborFilter>
) {
	const isMovie = title.__typename === 'Movie';
	const isShow = title.__typename === 'Show';
	const isSeason = title.__typename === 'Season';

	const { jwRating, imdbScore, imdbVotes } = title.content.scoring;
	const jwScore = Math.round((jwRating ?? 0) * 100);
	const jwVotes = title.content.interactions?.votesNumber ?? imdbVotes ?? 0;

	const getScore = (ratingSource: 'JW' | 'IMDB') => {
		if (ratingSource === 'JW') {
			return jwScore <= 0 ? '0' : `${jwScore}`;
		} else if (ratingSource === 'IMDB') {
			return imdbScore ?? 0;
		}
	};

	// Based on Google guidelines, `ratingCount` must be > 0.
	const getVotes = (ratingSource: 'JW' | 'IMDB') => {
		if (ratingSource === 'JW') {
			return Math.max(0, jwVotes);
		}

		if (ratingSource === 'IMDB') {
			return imdbVotes ?? 0;
		}

		return 0;
	};

	let titleContent = {} as any;
	const ratingCount = getVotes('JW');

	if (ratingCount >= 1 && title.content.scoring.jwRating != null && title.content.interactions?.votesNumber != null) {
		titleContent.aggregateRating = {
			'@type': 'AggregateRating',
			bestRating: '100',
			ratingCount: ratingCount.toString(),
			ratingValue: getScore('JW'),
			ratingExplanation:
				'The JustWatch rating is calculated by weighing and measuring how our users interact with shows & movies across different time periods and countries.',
		};
	}

	if (isMovie || isShow) {
		titleContent.name = title.content.title;
		titleContent.contentRating = title.content.ageCertification;

		// alternate name
		if (title.content.originalTitle !== title.content.title) {
			titleContent.alternateName = title.content.originalTitle;
		}

		if (isMovie) {
			titleContent.duration = getDurationString(title.content.runtime ?? 0);
		}

		if (isShow) {
			titleContent.containsSeason = getSeasons(title);
			titleContent.numberOfSeasons = title.totalSeasonCount;
		}
	}

	if (isSeason) {
		titleContent.name = `${title.show?.content.title || ''} ${title.content.title}`;
		titleContent.seasonNumber = title.content.seasonNumber;
		titleContent.numberOfEpisodes = title.totalEpisodeCount;
		titleContent.contentRating = title.show.content.ageCertification;

		titleContent.partOfSeries = {
			'@type': 'TVSeries',
			name: title.show?.content.title,
			url: new URL(title.show?.content.fullPath || '', APP_URL).toString(),
		};

		titleContent.episodes = title.episodes?.map(ep => ({
			'@type': 'TVEpisode',
			episodeNumber: ep.content.episodeNumber,
			name: ep.content.title,
			description: ep.content.shortDescription,
		}));
	}

	const linkedDataContent = {
		'@context': 'https://schema.org',
		'@id': new URL(title.content.fullPath, APP_URL).toString(),
		'@type': getType(title.objectType),

		...titleContent,

		dateCreated: title.content?.originalReleaseDate || title.content.originalReleaseYear,
		description: title.content.shortDescription,
		contentRating: titleContent.contentRating,
		image: title.content.fullPosterUrl ? `${JW_CONFIG.IMAGESCALER_URL}${title.content.fullPosterUrl}` : null,

		actor: getActorList(title),
		author: getCreditList(title, ROLE.WRITER),
		director: getCreditList(title, ROLE.DIRECTOR),
		genre: getGenresForTitle(genresByShortName, title),

		offers: {
			'@type': 'AggregateOffer',
			offerCount: title.offerCount,
			offers: title.watchNowOffer
				? [
						{
							'@type': 'Offer',
							url: title.watchNowOffer.standardWebURL,
						},
				  ]
				: [],
		},
	};

	const productionCountries =
		(isSeason ? title.show.content.productionCountries : title.content.productionCountries) ?? [];
	if (productionCountries.length > 0) {
		linkedDataContent.countryOfOrigin = (
			(isSeason ? title.show.content.productionCountries : title.content.productionCountries) ?? []
		)
			.map(country => getVm().$t(`WEBAPP_COUNTRY_${country}`))
			.find(Boolean);
	}

	if (title.watchNowOffer?.standardWebURL) {
		linkedDataContent.potentialAction = {
			'@type': 'WatchAction',
			target: new ClickoutUrl(title.watchNowOffer).set('uct_tryout', 1).toString(),
		};
	}

	const trailers = getTrailers(title, thumborFilter);
	if (trailers.length > 0) {
		linkedDataContent.about = [
			{
				'@type': 'ItemList',
				itemListElement: trailers.map((trailer, idx) => ({
					'@type': 'ListItem',
					position: idx + 1,
					url: trailer.contentUrl,
					item: {
						'@type': 'VideoObject',
						name: trailer.name,
						description: trailer.description,
						uploadDate: trailer.uploadDate,
						thumbnailUrl: trailer.thumbnailUrl,
						contentUrl: trailer.contentUrl,
					},
				})),
			},
		];
	}

	return linkedDataContent;
}

function getType(type: ObjectType) {
	switch (type) {
		case ObjectType.Movie:
			return 'Movie';
		case ObjectType.Show:
			return 'TVSeries';
		case ObjectType.ShowSeason:
			return 'TVSeason';
		default:
			return null;
	}
}

function getAggregateRating(titleContent?: Pick<MovieContent, 'scoring' | 'externalIds'>) {
	if (titleContent == null) return {};

	const { tmdbScore, tmdbPopularity, imdbVotes } = titleContent.scoring;
	const [jwRating] = getScorings(titleContent.scoring, titleContent.externalIds);
	const ratingCount = imdbVotes ?? Number(jwRating.score ?? '0') ?? 0;

	if (tmdbScore == null || tmdbPopularity == null || ratingCount <= 0) return {};

	return {
		aggregateRating: {
			'@type': 'AggregateRating',
			bestRating: '100',
			// if there are no imdbVotes, make it at least 1 since `ratingCount` must be > 0.
			ratingCount: ratingCount.toString(),
			ratingValue: jwRating.score,
			ratingExplanation:
				'The JustWatch rating is calculated by weighing and measuring how our users interact with shows & movies across different time periods and countries.',
		},
	};
}

function getVideoUploadDate(title: TitleDetail) {
	let uploadDate = null;
	const minimumDate = new Date('2021-06-16');
	if (title.content?.originalReleaseDate) {
		uploadDate = new Date(title.content.originalReleaseDate);
		uploadDate.setMonth(uploadDate.getMonth() - 1);
	} else if (title.content.originalReleaseYear) {
		uploadDate = new Date(title.content.originalReleaseYear, 11, 31);
	}
	return uploadDate && uploadDate > minimumDate ? uploadDate.toISOString() : minimumDate.toISOString();
}

// list all available trailers in the following order:
// - videobuster
// - dailymotion
// - youtube
function getTrailers(title: TitleDetail, thumborFilter: ReturnType<typeof createThumborFilter>) {
	const uploadDate = getVideoUploadDate(title);
	const clips = [
		// videobuster
		...(title.content?.videobusterClips || []).map(clip => ({
			...clip,
			thumbnailUrl: undefined,
			contentUrl: clip.externalId,
		})),
		// dailymotion
		...(title.content?.dailymotionClips || []).map(clip => ({
			...clip,
			thumbnailUrl: undefined,
			contentUrl: `https://www.dailymotion.com/crawler/video/${clip.externalId}`,
		})),
		// youtube
		...(title.content.clips || []).map(clip => ({
			...clip,
			thumbnailUrl: `https://img.youtube.com/vi/${clip.externalId}/mqdefault.jpg`,
			contentUrl: `https://www.youtube.com/watch?v=${clip.externalId}`,
		})),
	];

	return clips.slice(0, 5).map(clip => ({
		...clip,
		'@type': 'VideoObject',
		name: (clip as any).name || title.content.title,
		description: title.content.shortDescription,
		uploadDate,
		// @note: when fullBackdrops is available in gql:
		// thumbnailUrl: clip.thumbnailUrl || (title.content.fullBackdrops || []).find(Boolean),
		thumbnailUrl:
			clip.thumbnailUrl ||
			(title.content?.backdrops || [])
				.map(el => thumborFilter({ url: el.backdropUrl, imageType: 'backdrop' }))
				.find(Boolean),
	}));
}

function getGenresForTitle(genresByShortName: Record<string, Genre>, title: TitleDetail) {
	if (genresByShortName.length) {
		return title.content.genres.map(genre => genresByShortName[genre.shortName]?.translation);
	}
	return [];
}

function getCreditList(title: TitleDetail, type: string) {
	const credits = title.__typename === 'Season' ? title.show.content.credits : title.content.credits;
	return credits.filter(credit => credit.role === type).map(credit => schemaPerson(credit.name));
}

function getActorList(title: TitleDetail) {
	const credits = title.__typename === 'Season' ? title.show.content.credits : title.content.credits;
	if (credits == null) return [];

	return credits
		.filter(credit => credit.role === CreditRole.Actor)
		.map(credit =>
			credit.characterName ? schemaPerformanceRole(credit.name, credit.characterName) : schemaPerson(credit.name)
		);
}
function getDurationString(runtime: number | null) {
	if (runtime == null) return null;

	const hours = Math.floor(runtime / 60);
	const minutes = runtime % 60;

	// Time must be in ISO8601 format https://en.wikipedia.org/wiki/ISO_8601#Times
	// e.g.: PT1H39M0S
	return `PT${hours}H${minutes}M0S`;
}

function getSeasons(title: TitleDetail & { __typename: 'Show' }) {
	if (title.seasons == null || !title.content) return [];
	return title.seasons.map(season => {
		return {
			'@type': 'TVSeason',
			seasonNumber: season.content.seasonNumber,
			numberOfEpisodes: season.totalEpisodeCount,
			datePublished: season.content.originalReleaseYear,
			name: `${season.show?.content.title || ''} ${season.content.title}`,
			url: new URL(season.content.fullPath, APP_URL).toString(),
		};
	});
}

const schemaPerson = (name: string) => ({
	'@type': 'Person',
	name,
});

const schemaPerformanceRole = (name: string, characterName: string) => ({
	'@type': 'PerformanceRole',
	actor: schemaPerson(name),
	characterName,
});

const imgPathToUrl = (path?: string | null, profile = 's166') =>
	path ? path.replace('{profile}', profile).replace('{format}', 'jpeg') : '';

export function getArticleJsonLdContent(
	{ content, author, mainObjects }: ArticleFragment,
	rootState: any,
	rootGetters: any
) {
	const headline = (content?.title ?? '').match(/<h1[^>]*>(.*?)<\/h1>/);
	type MainObject = AssertByTypename<typeof mainObject, 'GenericTitleList' | 'Movie' | 'Show' | 'Season'>;
	const mainObject = mainObjects?.[0]?.node;

	if (headline == null || mainObject == null) return null;

	const route = getVm().$route;
	const url = `https://www.${JW_CONFIG.DOMAIN}${route.fullPath}`;

	const jsonLdContent: Record<string, any> = {
		'@context': 'https://schema.org',
		'@type': mainObject.__typename === 'GenericTitleList' ? 'Article' : 'NewsArticle',
		inLanguage: rootGetters['language/language'],
		headline: headline[1],
		url,
		isBasedOn: url,
		image: {
			'@type': 'ImageObject',
			url: imgPathToUrl(getArticleBackdrop(mainObject as MainObject, rootState), 's480'),
			thumbnail: {
				'@type': 'ImageObject',
				url: imgPathToUrl(getArticleBackdrop(mainObject as MainObject, rootState), 's480'),
			},
		},
		datePublished: content.createdAt,
		dateModified: content.updatedAt,
		publisher: {
			'@type': 'NewsMediaOrganization',
			name: 'JustWatch',
			logo: {
				'@type': 'ImageObject',
				url: `${JW_CONFIG.IMAGESCALER_URL}/${ASSETS_DIR}/img/logo/JustWatch-logo-small.png`,
				width: 253,
				height: 38,
			},
		},
		audience: {
			'@type': 'Audience',
			name: 'Movie Fans',
		},
		mainEntity: [],
	};

	if (mainObject.__typename === 'GenericTitleList' && mainObject.titles.edges) {
		jsonLdContent.mainEntity = mainObject.titles.edges.map(({ node }) => ({
			'@type': getType(node.objectType),
			name: node.content.title,
			url: `https://www.${JW_CONFIG.DOMAIN}${node.content.fullPath}`,
			description: node.content.shortDescription,
			image: JW_CONFIG.IMAGESCALER_URL + imgPathToUrl(node.content.posterUrl),
			...getAggregateRating(node.content as MovieContent),
		}));
	} else {
		type NewsMainObject = AssertByTypename<typeof mainObject, 'Movie' | 'Show' | 'Season'>;
		const title = mainObject as NewsMainObject;

		jsonLdContent.mainEntity.push({
			'@type': getType(title.objectType),
			name: title.content.title,
			url: `https://www.${JW_CONFIG.DOMAIN}${title.content.fullPath}`,
			image: JW_CONFIG.IMAGESCALER_URL + imgPathToUrl(title.content.posterUrl),
			...getAggregateRating(title.content),
		});
	}

	return {
		'@graph': [jsonLdContent, getAuthorJsonLdContent(author.content)],
	};
}

export function getAuthorJsonLdContent(author: AuthorDataFragment) {
	return {
		'@context': 'https://schema.org',
		'@type': 'Person',
		name: author.clearName,
		url: `https://www.${JW_CONFIG.DOMAIN}${author.fullPath}`,
		image: author.imageUrl,
		jobTitle: 'Official JustWatch Writer',
		sameAs: author.socialHandles?.length ? author.socialHandles.map(({ url }) => url) : undefined,
		reviewedBy: {
			'@type': 'Person',
			name: 'Samuel J Harries',
			hasOccupation: ['Writer', 'Editor'],
			email: 'samuel.james.harries@justwatch.com',
		},
		alumniOf: author.alumniOf,
		brand: author.brand,
		knowsLanguage: author.languagesSpoken,
		hasOccupation: author.occupation.map((occupation: string) => {
			return {
				'@type': 'Occupation',
				name: occupation,
			};
		}),
	};
}

export function getPopularJsonLdContent(popularPageData: GetPopularTitlesQuery['popularTitles']) {
	const popularTitles = popularPageData?.edges ?? [];
	const titleAmount = popularPageData?.totalCount;
	const country = getVm()?.$store.getters['language/country'];

	const schemaListItems = popularTitles.map((title, index) => {
		let itemType = '';
		let itemAudience = '';
		if (title?.node?.objectType === ObjectType.Show) {
			itemType = 'TVSeries';
			itemAudience = 'TV Series Fans';
		}
		if (title?.node?.objectType === ObjectType.Movie) {
			itemType = 'Movie';
			itemAudience = 'Movie Fans';
		}

		const genres = title?.node?.content?.genres?.map(genre => genre?.translation).join(', ') ?? '';

		const imageUrl =
			`https://www.justwatch.com/images${title?.node?.content?.posterUrl
				?.replace(/\{format\}/g, 'webp')
				.replace(/\{profile\}/g, 's718')}` ?? '';

		return {
			'@type': 'ListItem',
			position: index + 1,
			url: `https://www.justwatch.com${title?.node?.content?.fullPath}`,
			item: {
				'@type': itemType,
				name: title?.node?.content?.title ?? '',
				// added as unknown  to fix build error
				...getAggregateRating(title?.node?.content as unknown as MovieContent),
				director: title?.node?.content?.credits?.[0]?.name ?? '',
				duration:
					title?.node?.objectType === ObjectType.Movie
						? getDurationString(title?.node?.content?.runtime || null) ?? ''
						: '',
				audience: {
					'@type': 'Audience',
					name: itemAudience,
				},
				genre: genres,
				image: imageUrl,
			},
		};
	});

	return {
		'@context': 'http://schema.org',
		'@type': 'ItemList',
		mainEntityOfPage: `List of the Most Popular Movies & TV Series in ${
			Country[country.toUpperCase() as keyof typeof Country]
		}`,
		numberOfItems: titleAmount,
		itemListElement: schemaListItems,
	};
}

export function getNewJsonLdContent(newPageData: NewTitlesEdge[]) {
	const country = getVm()?.$store.getters['language/country'];

	const schemaListItems = newPageData.map((title: NewTitlesEdge, index: number) => {
		let titleNode = {} as Movie & Season;
		let titleContent = {} as MovieOrShowContent;
		let itemType = '';
		let itemAudience = '';
		if (titleNode?.show) {
			itemType = 'TVSeries';
			itemAudience = 'TV Series Fans';
			titleContent = titleNode?.show?.content as ShowContent;
		} else {
			itemType = 'Movie';
			itemAudience = 'Movie Fans';
			titleContent = titleNode?.content as MovieContent;
		}

		const genres = titleContent?.genres?.map(genre => genre?.translation).join(', ') ?? '';

		const imageUrl =
			`https://www.justwatch.com/images${titleContent?.posterUrl
				?.replace(/\{format\}/g, 'webp')
				.replace(/\{profile\}/g, 's718')}` ?? '';

		return {
			'@type': 'ListItem',
			position: index + 1,
			url: `https://www.justwatch.com${titleContent?.fullPath}`,
			item: {
				'@type': itemType,
				name: titleContent?.title ?? '',
				description: titleContent?.shortDescription,
				...getAggregateRating(titleContent as MovieContent),
				duration:
					titleNode?.objectType === ObjectType.Movie
						? getDurationString(titleContent?.runtime || null) ?? ''
						: '',
				audience: {
					'@type': 'Audience',
					name: itemAudience,
				},
				genre: genres,
				image: imageUrl,
			},
		};
	});

	return {
		'@context': 'http://schema.org',
		'@type': 'ItemList',
		mainEntityOfPage: `List of the newest Movies & TV Series in ${
			Country[country.toUpperCase() as keyof typeof Country]
		}`,
		numberOfItems: newPageData.length,
		itemListElement: schemaListItems,
	};
}
