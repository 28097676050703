import { initializeApp } from 'firebase/app';
import Cookies from 'js-cookie';
import LocalForage from 'localforage';
import 'localforage-getitems';
import Vue from 'vue';

import type { ApolloProvider } from 'vue-apollo';
import type { Route } from 'vue-router';
import type { Store } from 'vuex';

import type { Context } from '@/@types/ssr';

import { COUNTRY_EXCEPTIONS } from '@/constants/web-locales.constant';

import { OnboardingStatus } from '@/enums/onboarding-status';
import { WebLocale } from '@/enums/web-locale';

import { applyExperimentFromRoute, applyRoute } from '@/helpers/state-helper';
import { TrackingAppId, TrackingHelper } from '@/helpers/tracking/tracking-helper';

import { createRouter } from '@/routing';
import { globalRoutes } from '@/routing/global';

import {
	TrackingProviderGoogleAnalytics,
	TrackingProviderGoogleTagManager,
	TrackingProviderSnapchatPixel,
	TrackingProviderSnowplow,
	TrackingProviderTwitterPixel,
} from '@/helpers/tracking/providers';
import { STORE_NAMESPACE } from '@/store';
import { ReadyType } from '@/stores/ready.store';
import { generateJwId } from './helpers/uuid-helper';
import { getVm } from './helpers/vm-helper';

export {
	initApp,
	initClientStorage,
	initFirebase,
	initJwId,
	initLanguage,
	initLocale,
	initRouter,
	initSPA,
	initSSR,
	initStore,
	initStoreWatchers,
	initTrackers,
	initUncriticalRequests,
	jwIdServerClientHandover,
	refreshToken,
};

const MAX_AGE = 30 * 24 * 60 * 60 * 1000; // 30 Days
const JWID_COOKIE = 'jw_id' as const;

/**
 * Generates a valid JustWatch ID if needed and sets
 * it in a cookie for the rest of the app to use.
 */
function initJwId(context: Context): boolean {
	if (process.server) return !(JWID_COOKIE in context.req.cookies);
	if (document.cookie.includes(JWID_COOKIE)) return false;

	const jwId = generateJwId();

	fetch(JW_CONFIG.GRAPHQL_URL, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			query: `mutation RegisterDeviceId { registerDeviceId(input: "${jwId}") { deviceId } }`,
		}),
	})
		.then(resp => (resp?.ok ? null : resp.json()))
		.then(json => {
			const error = (json?.errors ?? [])[0];
			if (error) throw error;
		})
		.catch(error => {
			import('@/helpers/sentry-helper').then(({ captureMessageForSentry }) => {
				captureMessageForSentry(
					'[Fetch GraphQL RegisterDeviceId]:',
					{ error, where: '[main.common.ts]: initJwId' },
					'error'
				);
			});
		});

	document.cookie =
		`${JWID_COOKIE}=${jwId}` +
		`; expires=${new Date(+new Date() + MAX_AGE).toUTCString()}` +
		'; path=/; secure; SameSite=None';

	return true;
}

async function initFirebase() {
	try {
		const firebaseConfig = {
			apiKey: JW_CONFIG.FIREBASE_API_KEY,
			authDomain: JW_CONFIG.FIREBASE_AUTH_DOMAIN,
			projectId: JW_CONFIG.FIREBASE_PROJECT_ID,
			storageBucket: JW_CONFIG.FIREBASE_STORAGE_BUCKET,
			messagingSenderId: JW_CONFIG.FIREBASE_MESSAGING_SENDER_ID,
			appId: JW_CONFIG.FIREBASE_APP_ID,
		};
		initializeApp(firebaseConfig);
	} catch (err) {
		const error: any = err;

		const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
		captureMessageForSentry('Firebase Init Issue:', { error, where: '[main.common.ts] initFirebase()' }, 'error');
	}
}

/**
 * Client Only Method
 *
 * @param context
 */
async function refreshToken(context: Context) {
	const { store } = context;
	// This order is important, we want to try refresh the token before initializing the store
	// and making the requests to loadList and loadSettings. The user would be logged out if the refreshToken fails
	if (store.getters['user/isLoggedIn']()) {
		const token = await store.dispatch('user/refreshToken');

		// If token isn't returned then there was an error returning token
		if (!token) {
			context.showRefreshTokenError = true;

			TrackingHelper.trackEvent('refresh_token_error', {
				action: 'main_ssr_refresh',
			});
		}
	}

	if (store.state.user.accessToken) {
		const { currentUser, exchangeToken } = await import('@/helpers/user-helper');

		const user = await currentUser();

		if (!user) {
			const exchangedToken = await exchangeToken(store);
			if (!exchangedToken) {
				context.showRefreshTokenError = true;
			}
		}
	}
}

async function initClientStorage() {
	let driver;
	if (LocalForage.supports(LocalForage.LOCALSTORAGE)) {
		driver = LocalForage.LOCALSTORAGE;
	}

	if (!driver) {
		throw new Error(`[Local storage] local forage drivers not available`);
	}

	await LocalForage.config({
		name: STORE_NAMESPACE,
		driver: driver,
	});
}

async function initApp(context: Context) {
	const { store, webLocale, url } = context;
	// these requests will be checked first, if they have been done on the server already and will skip accordingly
	if (process.server || !process.ssr) {
		await coreAppRequests(store);
	}

	if (!context.store.state.user.onboardingStatus) {
		const { loadOnBoardingStatus } = await import('@/helpers/onboarding-helper');
		await loadOnBoardingStatus(store, webLocale, url, true);
	}
}

async function initRouter(context: Context) {
	const doEveryPageView = async (context: Context, to: Route, from?: Route) => {
		const { meta, fullPath } = to;

		// temporary fix for dev env @to-do fix server
		if (((from?.name !== 'app.refreshtoken' && !to.name && to.path !== '/') || to.path.indexOf('/refresh')) === 0) {
			// Avoid Routing when
			return;
		}

		// @note: setActiveRoute needs to happen before fetchUrlMetadata
		// maybe it's better to merge these 2, since they aren't called anywhere else, either
		context.store.dispatch('routing/setActiveRoute', { activeRoute: to, router: context.router });
		// we should tie pagesVisited to activeRoute.
		// with a flaky connection there is a chance that we transitioned to a new route (activeRoute),
		// but the pagesVisited hasn't reflected that because it's still awaiting urlMetadata.
		context.store.commit('tracking/INCREASE_PAGES_VISITED');
		if (to.path !== from?.path) {
			await context.store.dispatch('routing/fetchUrlMetadata', to.path);
		}

		// ###### TRACK PAGE ######
		// before, we awaited trackPage, to make sure the `tracking.queues` are filled with all necessary state.
		// but since we have overlapping awaits with `titleDetails/whenDetailTitleLoaded`, where loading the title detail happens in the component (therefore after `doEveryPageInit()`), we can't await on it.
		//
		// if there were any async actions in trackPage, that aren't resolved beforehand, trackPage wouldn't be able to finish writing into `tracking.queues` and send it over to __DATA__.
		// so here the async actions that happen in trackPage, that are already resolved beforehand and don't yield additional awaits:
		// - urlMetadata: resolved
		// - getContexts: only unresolved if there is an unknown pagetype
		// - titleDetails/whenDetailTitleLoaded: when 'title' is present in `routes.meta.contexts` it needs to be awaited, but will be resolved in TitleDetail.vue@serverPrefetch
		const metaContexts = (meta?.contexts || []) as string[];

		// we track page views ourselves for title detail pages and (nearly all) sport pages
		const sportPageExceptions = [
			// 'app.sports.all', // this isn't included because it doesn't have any sport context
			'app.sports.overview',
			'app.sports.competition', // @todo implement with sport context
			'app.sports.event',
			'app.sports.team|competition',
		];
		if (!metaContexts.includes('title') && !sportPageExceptions.includes(to.name || '')) {
			TrackingHelper.trackPage(fullPath);
		}
	};

	// check for first routing and possibly update store state from url (e.g. filters from url)
	context.router.beforeEach(async (to: Route, from: Route, next: () => void) => {
		const firstRouting = from?.name === null || from?.name === 'app.refreshtoken';

		// persist scroll position for each tab
		if (!firstRouting && process.client) {
			const tab = from?.meta?.tab === 'sports' ? `${from?.meta?.tab}-${from?.meta?.data}` : from?.meta?.tab;
			context.store.commit('routing/SET_SCROLL_POSITION', { tab, y: window.scrollY });
		}

		// fire doEveryPageView only when:
		// - on the server
		// OR
		// - is not first routing `!firstRouting`
		// OR
		// - (first routing `firstRouting` AND activeRoute hasn't been set by the server `!context.store.state.routing.activeRoute`)
		// OR
		// - is SPA app BUILD_CONFIG.globals.BUILD_TARGET === 'SPA'
		if (
			process.server ||
			!firstRouting ||
			(firstRouting && !context.store.state.routing.activeRoute) ||
			BUILD_CONFIG.globals.BUILD_TARGET === 'SPA'
		) {
			const promise = doEveryPageView(context, to, from);
			if (process.server) {
				// we only need the server to await the promise, the client can move forward already
				await promise;
			}
		}

		if (firstRouting && process.client) {
			// NOTE: deeplinking an experiment might show hydration errors around the experiment component
			await applyExperimentFromRoute(to, context.store);
		}

		// check first page view
		if ((firstRouting && process.server) || (process.client && to.path !== from.path)) {
			// @todo translate route parameters to filters if there are any
			await applyRoute(to, context.store);
		}

		next();
	});

	context.router.afterEach(async (to: { meta: { onActivate: any } }, from: any) => {
		const onActivate = to.meta.onActivate;
		// call activation function from routes
		if (onActivate) {
			onActivate(context.store, to, from);
		}
	});
}

async function initStore(context: Context) {
	const { store, webLocale, url } = context;

	store.dispatch('app/setSeoUser', context.ssrData.isSeoUser);

	// NOTE(valerio.mazza): failsafe for filter.store to remove after a proper refactor of the filter.store
	if (!store.state.filter[webLocale]) {
		// init filter here before setting webLocale for reactivity reason (webLocale is watched everywhere)
		store.dispatch('filter/initFiltersPerLocale', webLocale, { root: true });
	}

	if (store.state.user.jwId) {
		Vue.$jw.ready?.setReady(ReadyType.JW_ID);
	}

	/*
	 * Only fetch user settings for "users" (aka has a JWID).
	 * The check for `process.client` is therefore redundant but there for extra safety.
	 */
	if (process.client && store.state.user.jwId && url.indexOf('/refresh') === -1) {
		await store.dispatch('user/loadSettings');
	} else {
		// Unlock ready for USER_SETTINGS_LOADED
		// Note(valerio.mazza): look into removing this alltogether
		Vue.$jw.ready?.setReady(ReadyType.USER_SETTINGS_LOADED);
	}
}

async function initSPA(context: Context, apolloProvider: ApolloProvider): Promise<any[]> {
	// initialize id service
	if (context.store.state.user.jwId) {
		context.ssrData = { isSeoUser: false };
		return await context.store.dispatch('user/setJwId', context.store.state.user.jwId);
	} else {
		context.ssrData = { isSeoUser: true };
		return await context.store.dispatch('user/fetchJwId', apolloProvider);
	}
}

function initSSR(context: Context, isSeoUser: boolean) {
	context.ssrData = { isSeoUser };
}

function initUncriticalRequests(context: Context) {
	if (process.server || !process.ssr) {
		return [context.store.dispatch('footer/fetchTopTitles'), context.store.dispatch('footer/fetchTopArticles')];
	}
	return [];
}

function jwIdServerClientHandover(store: Store<any>) {
	const localJwUser = JSON.parse(localStorage.getItem('jw/user') ?? 'null');
	const jwId = Cookies.get(JWID_COOKIE) ?? localJwUser?.jwId;

	return store.dispatch('user/setJwId', jwId);
}

async function initStoreWatchers(context: Context) {
	const { i18n, store, router, url } = context;

	// #### webLocale ####
	store.watch(
		(state: any) => state.language.webLocale,
		async (webLocale: WebLocale, oldWebLocale: WebLocale | undefined) => {
			if (webLocale !== oldWebLocale && !store.state.filter[webLocale]) {
				store.dispatch('filter/initFiltersPerLocale', webLocale);
			}

			if (webLocale !== oldWebLocale) {
				if (!router) {
					// change locale or create router (and change locale)
					context.router = createRouter(webLocale, store);
				} else {
					// router is already initialized, change country on the fly
					router.changeLocale(webLocale, store);
				}

				const currentUrl = store.state.routing.activeRoute.path;
				initLanguage({ i18n, store, webLocale, router, url: currentUrl });

				store.dispatch('user/loadSettings');

				store.dispatch('constant/fetchPackages');
				store.dispatch('constant/fetchConstants');
				store.dispatch('footer/fetchTopTitles');
				store.dispatch('footer/fetchTopArticles');

				// Experiment Data
				store.dispatch('experiment/fetchExperimentsConfig');
			}
		},
		{ immediate: false }
	);

	// #### overriddenLanguage ####
	store.watch(
		(state: any) => state.language.overriddenLanguage,
		async (language: string, oldLanguage: string | undefined) => {
			if (language) {
				const activeRoute = getVm()?._route ?? router?.match(context.url);
				await store.dispatch('language/fetchTranslation', { language, activeRoute });
				await store.dispatch('language/setLanguage', { language, i18n });

				store.dispatch('experiment/fetchExperimentsConfig');
			}
		}
	);

	// watch accessToken when user login set onboarding accordingly
	store.watch(
		(state: any) => state.user.accessToken,
		async (value: string) => {
			if (value) {
				await store.dispatch('user/loadSettings');
				const { loadOnBoardingStatus } = await import('@/helpers/onboarding-helper');
				const webLocale = store.state.language.detectedWebLocale || store.state.language.webLocale;
				await loadOnBoardingStatus(store, webLocale, url);
			}
		}
	);

	// watch loggedInProviders when user login set onboarding accordingly
	store.watch(
		(state: any) => state.user.loggedInProviders,
		async (providers: Array<any>, oldProviders: Array<any> | undefined) => {
			if (providers.length > 0 && store.state.user.settings.taste_survey_completed) {
				store.dispatch('user/saveOnboardingStatus', OnboardingStatus.USER);
			} else if (providers.length > 0 && !store.state.user.settings.taste_survey_completed) {
				store.dispatch('user/saveOnboardingStatus', OnboardingStatus.SIGNED_UP);
			}
		},
		{ immediate: false }
	);

	/* *************************** Immediate watchers ****************************/

	// #### onboardingStatus ####
	store.watch(
		(state: any) => state.user.onboardingStatus,
		async (onboardingStatus: OnboardingStatus, oldOnboardingStatus: OnboardingStatus | undefined) => {
			if (onboardingStatus && url === '/') {
				if (
					onboardingStatus !== OnboardingStatus.TASTE_ONBOARDING &&
					onboardingStatus !== oldOnboardingStatus
				) {
					TrackingHelper.trackEvent('home_page_view', {
						action: onboardingStatus,
						property: store.state.language.detectedWebLocale || store.state.language.webLocale,
						nonInteraction: true,
					});
				}
			}
		},
		{ immediate: true }
	);
}

/*************       initLocale        *************/

async function initLocale(context: Partial<Context>) {
	let initAppLocale: WebLocale;
	const { store, url = '' } = context;

	const path = url.split('?')[0];
	const slug = /^\/[\w\d]+/.exec(url)?.[0] || '';
	const isGlobalRoute = globalRoutes.includes(path) || globalRoutes.includes(slug);

	if (isGlobalRoute) {
		initAppLocale = await getWebLocaleFromLocation(context);
	} else {
		initAppLocale = await getWebLocaleFromPath(context, path);
	}

	await store.dispatch('language/changeWebLocale', initAppLocale);

	return initAppLocale;
}

async function getWebLocaleFromLocation(context: Partial<Context>) {
	let locale =
		context.store.state.user.persistedWebLocale ||
		(process.client && context.store.state.language.detectedWebLocale);

	// For Global routes we still need to know the location in the server
	// if server OR if SPA app
	if (!locale && process.server) {
		// This header is only meant for global routes because the region is not in the URL
		// IN SSR, DO NOT PASS THIS TO CLIENT (use `getUserLocationCountryCode` instead)
		const region = context.req.headers['x-client-geo-region'];
		locale = region?.toLowerCase();
	}

	// get Browser Locale as last resort
	// happens for SPA visitors that do not pass through SSR first
	if (!locale && process.client) {
		const { getUserLocationCountryCode } = await import('@/helpers/geo-location-helper');
		locale = getUserLocationCountryCode()?.toLowerCase() as WebLocale;
	}

	// hard fallback to US just in case
	if (!locale) locale = 'us';

	context.store.dispatch('language/setDetectedWebLocale', locale);
	return context.store.state.language.detectedWebLocale;
}

async function getWebLocaleFromPath(context: Partial<Context>, path: string) {
	const { store } = context;
	const locale = path && path.length >= 3 && path.split('/')[1];

	const webLocale = locale && COUNTRY_EXCEPTIONS[locale] ? COUNTRY_EXCEPTIONS[locale] : locale;
	await store.dispatch('language/setDetectedWebLocale', webLocale);
	return store.state.language.detectedWebLocale;
}

/*************       initLanguage        *************/

async function initLanguage(context: Partial<Context>): Promise<any> {
	const { i18n, store, webLocale, router } = context;
	let language = store.state.user.persistedOverriddenLanguage;

	// Get language from localStorage or detect browser language
	if (store.state.user.persistedOverriddenLanguage) {
		language = store.state.user.persistedOverriddenLanguage;
		store.dispatch('language/setDetectedLanguage', language);
	} else {
		store.dispatch('language/setLanguageFromLocale', webLocale);
		language = store.state.language.overriddenLanguage;
	}

	// Hard fall back to en
	if (!language) {
		language = 'en';

		const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
		captureMessageForSentry('[Language] not able to detect language, hard fallback to en', {
			where: '[main.common.ts] initLanguage Failed to init',
		});
	}

	const activeRoute = router?.match(context.url || `/${webLocale}`); // when changing webLocale within the app, `context.url` isn't set
	await store.dispatch('language/fetchTranslationAndSetLanguage', { i18n, language, activeRoute });
	await store.dispatch('meta/setAppDirection', { language });

	return language;
}

/**
 *
 */
async function coreAppRequests(store: Store<any>) {
	let parallelRequests: Promise<any>[] = [];

	// Fetch Packages early (2 attempts)
	parallelRequests = [
		...parallelRequests,
		store.dispatch('constant/fetchPackages'),
		store.dispatch('constant/fetchConstants'),
	];

	// Experiments
	if (!Object.keys(store.state.experiment.experiments).length) {
		parallelRequests.push(store.dispatch('experiment/fetchExperimentsConfig'));
	}

	return Promise.all(parallelRequests);
}

async function initTrackers(store: Store<any>, force: boolean = false) {
	const { hasDoNotTrack } = await import(/* webpackChunkName: "constent-helper" */ '@/helpers/consent-helper');

	try {
		TrackingHelper.setStore(store);

		const consents = store.state.user.settings?.jw_consents || {};
		const deviceDoNotTrack = false;
		const jwId = store.state.user.jwId;
		let snowPlowJwId = jwId;

		if (!snowPlowJwId) {
			const randDigits = Array(17)
				.fill(0)
				.map(x => Math.random().toString(36).charAt(2))
				.join('');
			snowPlowJwId = `fake-${randDigits}`;
		}

		// initialize trackers
		// pass over the instance to the store for snowplow provider beeing
		// able to compile a list of contexts from global state

		const { TrackingProviderFacebookPixel, TrackingProviderTiktokPixel } = await import(
			/* webpackChunkName: "tracking-providers" */ '@/helpers/tracking/providers'
		);

		await Vue.$jw.ready?.waitFor(ReadyType.EXPERIMENTS_LOADED);

		// initialize first with trackers that on web and mobile
		const trackers = [
			// SNOWPLOW
			new TrackingProviderSnowplow({
				userId: snowPlowJwId,
				appId: TrackingAppId,
				cookieDomain: global.JW_CONFIG.SNOWPLOW_COLLECTOR_ENDPOINT,
				platform: 'web',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents),
				eventMethod: 'post',
				postPath: '/5e87d/bx6',
			}),
			// FACEBOOK PIXEL
			new TrackingProviderFacebookPixel({
				pixelId: '416036085213374',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['facebook']),
			}),
			// TIKTOK PIXEL
			new TrackingProviderTiktokPixel({
				pixelId: 'C5O26T5O3VNUQLVLL8P0',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['tiktok']),
			}),
			// GOOGLE ANALYTICS
			new TrackingProviderGoogleAnalytics({
				id: ['UA-58489323-1', 'UA-58489323-4'],
				debug: {
					enabled: false, // process.env.NODE_ENV === 'development',
				},
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['google']),
				userId: jwId,
			}),
			// GOOGLE TAG MANAGER
			new TrackingProviderGoogleTagManager({
				containerId: 'GTM-53RWB5',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['google']),
			}),
			new TrackingProviderTwitterPixel({
				pixelId: 'o0dvi',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['twitter']),
			}),
			// SNAPCHAT PIXEL TRACKER
			new TrackingProviderSnapchatPixel({
				pixelId: '5b32c5b6-8e7f-4495-93ab-0061758f9a18',
				doNotTrack: hasDoNotTrack(deviceDoNotTrack, consents, ['snapchat']),
				email: store.state.user.email,
			}),
		].filter(Boolean);

		TrackingHelper.initializeTrackers(trackers, force);
	} catch (err) {
		const error: any = err;
		const { captureMessageForSentry } = await import('@/helpers/sentry-helper');
		captureMessageForSentry('Main Init Issue:', { error, where: '[main.common.ts] initializeTracking' }, 'error');
	}
}
