import { TrackingProvider, TrackingProviderPropertiesInterface } from './tracking-provider';
import { SnowplowContext } from './snowplow-contexts';
import { DataProcessingService } from '@/helpers/usercentrics-helper';

declare global {
	interface Window {
		ga: any;
	}
}
/** trackers accesses the Analytics object and tracks events for each active tracker */
function trackers(args: any[]) {
	try {
		window.ga(function () {
			const trackers = window.ga.getAll();
			const action = args[0];
			for (let i = 0; i < trackers.length; i++) {
				/** send events to named tracker, args[0] is always the action */
				args[0] = trackers[i].get('name') + '.' + action;
				tracker(args);
			}
		});
	} catch (error) {
		console.error('Google Analytics is undefined [trackers]', error);
	}
}

function tracker(args: any[]) {
	try {
		window.ga(...args);
	} catch (error) {
		console.error('Google Analytics is undefined [tracker]', error);
	}
}

interface GoogleAnalyticsConfig {
	/** one or multiple full google analytics tracking id, f.e. "UA-12347161-Y" */
	id: string | string[];
	/** optional debugging options */
	debug?: {
		/** when set to true use analytics_debug instead of analytics */
		enabled?: boolean;
		/** when set to tru-ish value, set to false while in development mode */
		sendHitTask?: boolean;
	};
	doNotTrack: boolean;
	userId: string;
}

export class TrackingProviderGoogleAnalytics extends TrackingProvider {
	dataProcessingService = DataProcessingService.GOOGLE_ANALYTICS;

	constructor(private config: GoogleAnalyticsConfig) {
		super();

		// config validation
		if (!this.config.id) {
			throw new Error('Expected "config.id"');
		}
	}

	initialize() {
		return new Promise<void>((resolve, reject) => {
			const script = document.createElement('script');
			const filename = (this.config.debug || {}).enabled ? 'analytics_debug' : 'analytics';
			script.innerHTML = `
			(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			})(window,document,'script','https://www.google-analytics.com/${filename}.js','ga');
			`;
			// @ts-ignore
			document.head.appendChild(script);

			// create one tracker for each id given
			const trackingIds = Array.isArray(this.config.id) ? this.config.id : [this.config.id];
			trackingIds.forEach((trackingId, index) => {
				/** name the trackers */
				tracker([
					'create',
					trackingId,
					'auto',
					{
						name: 'gaTracker' + index,
						userId: this.config.userId,
						clientId: this.config.userId,
					},
				]);
			});
			// https://developers.google.com/analytics/devguides/collection/analyticsjs/debugging
			if (this.config.debug && typeof this.config.debug.sendHitTask !== 'undefined') {
				trackers(['set', 'sendHitTask', this.config.debug.sendHitTask]);
			}
			// when present, the IP address of the sender will be anonymized.
			trackers(['set', 'anonymizeIp', true]);
			resolve();
		});
	}

	async trackPage({ path, title }: { path?: string; title?: string }) {
		// if (!this.config.doNotTrack) {
		trackers(['set', { page: path, title, dimension1: this.config.userId }]);
		trackers(['send', 'pageview']);
		// }
	}

	refreshPermanentAudiences(permanent_audiences: string[] = []) {}

	async trackEvent(category: string, properties: TrackingProviderPropertiesInterface, contexts: SnowplowContext[]) {
		// if (!this.config.doNotTrack) {
		// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
		trackers([
			'send',
			'event',
			{
				eventCategory: category || 'Event',
				eventAction: properties.action,
				eventLabel: properties.label,
				eventValue: properties.value,
				path: properties.path,
				nonInteraction: properties.nonInteraction,
			},
		]);
		// }
	}
}
