import type { Goal, Store } from 'alephbet';
import { Experiment } from 'alephbet';
import type { Commit } from 'vuex';

import { SnowplowExperimentContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import type { UserExperiment, UserExperiments } from '@/stores/user.store';

export interface ExperimentMap {
	[key: string]: ExperimentConfig;
}

export interface ExperimentConfig {
	alias: string;
	countries: string[];
	id: string;
	languages: string[];
	name: string;
	platforms: string[];
	sample: number[];
	timestamps: {};
	variants: ExperimentVariant[];
	version?: string;
	description?: string;
}

export type ExperimentData = {
	name: string;
	variants: ExperimentVariant[];
	goals: Goal[];
};

export interface ExperimentVariant {
	name: string;
	weight: number;
	is_control?: boolean;
}

const proportion = Math.random();

/**
 * checks according to the experimentPreference and proportion (will be rolled once per session)
 * if a user is in the sample or not.
 * also take into account all other experiments for that platform.
 */
function checkSample(experimentName: string, experimentsList: ExperimentMap): 1 | 0 {
	if (!(experimentName in experimentsList)) return 0;

	const sample = experimentsList[experimentName].sample;
	if (Array.isArray(sample) && sample.length !== 2) {
		console.error('[ERROR] Only array of size 2 is allowed');
	}
	const start = Array.isArray(sample) ? sample[0] : 0;
	const end = Array.isArray(sample) ? sample[1] : sample;

	return <1 | 0>Number(start <= proportion && end > proportion);
}

/**
 * Debug mode for Alephbet
 */
// (AlephBet as any).options.debug = true;

/**
 * Correctly setup experiment context for given experiment, variant
 *
 * @param {any} experiment
 * @param {string} variant
 *
 * @returns {SnowplowExperimentContext} context
 */
const getExperimentContext = (experiment: Experiment, variant: string) => {
	const { name } = experiment;
	const { description, version } = experiment.options;

	return new SnowplowExperimentContext(name, version, variant, description);
};

const DEFAULT_EXPERIMENT_CONFIG = (tracking: boolean) => ({
	tracking_adapter: {
		experiment_start: (experiment: Experiment, variant: string) => {
			const { name } = experiment;
			// Add Experiment Context
			const context = getExperimentContext(experiment, variant);
			const properties = {
				action: variant,
				label: '__ACTIVATED__',
				nonInteraction: true,
			};
			if (tracking) {
				TrackingHelper.trackEvent(`EXP_${name}`, properties, [context]);
				console.log('[EXPERIMENT ACTIVATED]', name, variant);
			} else {
				console.log(`[EXPERIMENT WITHOUT TRACKING][ACTIVATED] EXP_${name} `, variant, experiment);
				console.log(`[EXPERIMENT WITHOUT TRACKING][SNOWPLOW] EXP_${name}: `, properties, [context]);
			}
		},

		goal_complete: (experiment: Experiment, variant: string, eventName: string) => {
			const { name } = experiment;
			// Add Experiment Context
			const context = getExperimentContext(experiment, variant);
			const properties = {
				action: variant,
				label: `GOAL_${eventName}`,
				nonInteraction: true,
			};

			if (tracking) {
				TrackingHelper.trackEvent(`EXP_${name}`, properties, [context]);
				console.log('[GOAL COMPLETED]', name, eventName, variant);
			} else {
				console.log(`[EXPERIMENT WITHOUT TRACKING][GOAL COMPLETED] EXP_${name} `, name, eventName, variant);
				console.log(`[EXPERIMENT WITHOUT TRACKING][SNOWPLOW] EXP_${name}:`, properties, [context]);
			}
		},
	},
});

const platform = 'web';

const getExpActiveVariants = (experiments: ExperimentMap, userExperiments: UserExperiments, control = false) => {
	const activeVariants = {} as Record<string, string>;
	for (const name in userExperiments) {
		const userExperiment = userExperiments[name];
		const config = experiments[name];
		if (isExperimentActive(config, userExperiment)) {
			if ((control && userExperiment.variant === 'control') || userExperiment.variant !== 'control')
				activeVariants[name] = userExperiment.variant;
		}
	}
	return activeVariants;
};

const isExperimentActive = (config?: ExperimentMap[keyof ExperimentMap], userExperiment?: UserExperiment) => {
	return config && config.sample && config.platforms.indexOf(platform) !== -1 && userExperiment;
};

/**
 * adds an experiment and its goal.
 * it also checks if the experiment has been enabled for the running platform.
 */
const addExperiment = (
	experimentData: ExperimentData,
	experimentsList: ExperimentMap,
	userExperiments: UserExperiments,
	userId: string | undefined,
	store: Store,
	commit: Commit,
	shouldBeActive = true
) => {
	const { name } = experimentData;

	// don't add the experiment if it's not in the CMS experiment config
	if (!(name in experimentsList)) {
		return;
	}

	const config = experimentsList[name];
	const userExperiment = userExperiments[name];

	const active = isExperimentActive(config, userExperiment);
	if ((shouldBeActive && !active) || (!shouldBeActive && active)) {
		return;
	}

	const tracking = userExperiment?.tracking ?? true;
	const variants = userExperiment?.preferred
		? experimentData.variants.filter(variant => variant.name === userExperiment.variant)
		: experimentData.variants;

	const { version = '1', description = '' } = config;

	const userActiveExperiments = Object.keys(userExperiments).filter(key => userExperiments[key].in_sample);
	const userActiveExperimentsSamples = userActiveExperiments.map(el => experimentsList[el]?.sample).filter(Boolean);

	const experimentConfigObj = {
		name,
		user_id: userId,
		sample: checkSample(name, experimentsList),
		trigger: () => {
			return hasOverlap(userActiveExperimentsSamples, config.sample);
		},
		version: version.toString(),
		description,
		variants: variants.reduce((root: Record<string, { activate: () => void; weight: number }>, item) => {
			root[item.name] = {
				activate: () => {},
				weight: item.weight,
			};
			return root;
		}, {}),
	};
	const experiment = new Experiment({
		...DEFAULT_EXPERIMENT_CONFIG(tracking),
		...experimentConfigObj,
		storage_adapter: {
			get: (key: string) => store[key],
			set: (key: string, value: any) => {
				commit('user/SET_ALEPHBET_WITH_KEY_VALUE', { key, value }, { root: true });
			},
		},
	});

	// attach goals
	experimentData.goals?.forEach((goal: Goal) => {
		goal.add_experiment(experiment);
	});
};

/*
 * Function to check if there is an overlap between the given range (experimentSample)
 * and any of the ranges in the list of active user experiment samples (userExperimentActiveSamples).
 */
function hasOverlap(userActiveExperimentsSamples: number[][], experimentSample: number[]): boolean {
	// [[0 , 0.1] , [0.2,0.5]]  --- [0.3,0.4] ==> this one should return true
	// [[0 , 0.1] , [0.2,0.5]]  --- [0.8,0.6] ==> this one should return false
	// [[0 , 0.1] , [0.2,0.5]]  --- [0.3,0.6] ==> this one should return true
	// [[0 , 0.1] , [0.2,0.5]]  --- [0.1,0.2] ==> this one should return false
	if (userActiveExperimentsSamples.length === 0) return true;
	const [start, end] = experimentSample;
	return userActiveExperimentsSamples.some(([userStart, userEnd]) => {
		// If either start or end of experiment sample lies within the range of any user sample, return true
		return (start >= userStart && start < userEnd) || (end > userStart && end <= userEnd);
	});
}

export { addExperiment, getExpActiveVariants, isExperimentActive };
